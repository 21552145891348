import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

// MUI:
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import Container from '@mui/material/Container';
import { SnackbarProvider } from 'notistack';
import store from './redux/store';
import { ruRU } from '@mui/material/locale'
import { Settings } from "luxon";
import RouteContainer from './components/RouteContainer';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Notifier } from './components/Notifier';


const themeDefault = createTheme({
  palette: {
    background: {
      default: "#f0f0f0"
    }
  }
}, ruRU);

//luxon settings
Settings.defaultZone = "Europe/Moscow";

document.documentElement.lang='ru';
document.documentElement.translate=false;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /*<React.StrictMode>*/
    <Provider store={store}>
      <ThemeProvider theme={themeDefault}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Notifier />
          <Container>
            <Box sx={{ my: 2 }}> {/* margin-top and margin-bottom */}
              <BrowserRouter>
                <RouteContainer />
              </BrowserRouter>
            </Box>            
          </Container>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
