import React, { useState } from "react"
import { Card, Grid, Typography, Link, Rating, Button } from "@mui/material"
import htmlParse from 'html-react-parser';
import { PurchaseStageAndDates } from "./purchase-stage-and-dates";
import { useSelector, useDispatch } from "react-redux";

export const PurchaseCard = (props) => {
    const purchase = props.purchase;

    const reduxState = useSelector((state) => {
        return {
            currentPage: state.pagesState.currentPage
        };
    });
    const dispatch = useDispatch();

    const markPurchase = () => {
        const action = {
          type: 'ADD_UPLOAD_PURCHASE',
          uploadPurchaseId: purchase.id,
          currentPage: reduxState.currentPage
        }
        dispatch(action);
    }
    
    const unmarkPurchase = () => {
        const action = {
          type: 'REMOVE_UPLOAD_PURCHASE',
          uploadPurchaseId: props.purchase.id,
          currentPage: reduxState.currentPage
        }
        dispatch(action);
    }

    const getLaw = (purchase) => {
        switch(purchase.law) {
          case 1:
            return '44-ФЗ';
          case 2:
            return '223-ФЗ';
          case 3:
            return 'Коммерческие';
          case 4: 
            return 'ПП РФ 615';
          default:
            return 'Не определено';
        }    
      }

    return (
        <React.Fragment>
            <Card sx={{mb: 1, ml: 1, mr: 1}}>
                <Grid container>
                {purchase.recommended === true ? 
                    <Grid item>
                      <Typography variant="overline" color="red">Рекомендовано Эксперт-Центром!</Typography>
                    </Grid>
                    : null
                  }
                </Grid>
                <Grid container sx={{mb: 1}}>
                    <Grid item>
                        <Link target="_blank" href={"/purchases/" + purchase.id} >
                            <Typography className="highlighted">
                                {htmlParse(purchase.title)}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" sx={{ pt: 3 }} color="text.secondary" component="div">
                        {[getLaw(purchase), purchase.methodType, purchase.number].filter(Boolean).join(', ')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 1}}>
                    <Grid item>
                        <Typography>
                            Стоимость: {purchase.price}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 1}}>
                    <Grid item>
                        <PurchaseStageAndDates purchase={purchase} />
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 1}}>
                    <Grid>
                        <Typography variant="caption" sx={{ pt: 3 }} component="div">Заказчик: { purchase.customerOrganizationName}</Typography>
                        <Rating value={parseFloat(purchase.customerRating)} precision={0.1} readOnly />
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 1}}>
                    {props.isMarked? (
                            <Button 
                                variant="contained" color="success"
                                onClick={() => unmarkPurchase()}
                            >
                                Отмечено
                            </Button>
                        )
                        :(
                            <Button 
                                variant="outlined"
                                onClick={() => markPurchase()}
                            >
                                Отметить
                            </Button>
                        )
                    }
                </Grid>
            </Card>
        </React.Fragment>
    )
}
