import React from 'react';
import { Typography } from '@mui/material'; 
import { DateTime } from 'luxon';

export const AuthFooter = (props) => {
	return (
    <>
      <Typography style={{ fontSize: 'small', filter: 'brightness(0.6)', textAlign: 'center' }}>
        <a target="_blank" href={process.env.REACT_APP_IDENTITY_FRONTEND_URI ?? '' + "/personal-data-policy.html"}>Политика обработки персональных данных</a>
      </Typography>
      <Typography style={{ fontSize: 'small', textAlign: 'center'}}>
        © {DateTime.now().year ?? ''} Эксперт Центр
      </Typography>
    </>
  );
}
