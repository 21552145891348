import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import Menu from '../../components/Menu';
import AuthHeader from './AuthHeader';
import { AuthFooter } from './AuthFooter';
import UserManager from '../../userManager';
import { connect } from 'react-redux';

import { 
	CircularProgress, Box
} from '@mui/material';

class AuthLayout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true	
		};		
	}
	
	componentDidMount() {
		UserManager.getUser().then(user => {
			if (user && !user.expired) {
				this.props.onPermissions(user.profile.permission);
				this.setState({
					isLoading: false
				});
			} else {
				UserManager.signinRedirect();
			}
		});
	}

	render() {
		if (this.state.isLoading) {
			return (
				<Box sx={{ display: 'flex' }}>
					<CircularProgress />
				</Box>
			)
		} else {
			return (
				<div>
					<AuthHeader/>
					<Menu/>
					<Outlet />
					<AuthFooter />
				</div>
			)
		}
	}
}

function mapStateToProps(state) {
		return {
			
		}
  }
  
  function mapDispatchToProps(dispatch) {
	return {
		onPermissions: (permissions) => {
			const action = {
			  type: 'PERMISSIONS',
				permissions: permissions
			};
			dispatch(action);
		}		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);