import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Dialog, TextField, Typography, Grid, Box, CircularProgress, FormHelperText } from '@mui/material';
import Tree from 'rc-tree';
import '../css/rc-tree.css';
import OkpdService from '../services/OkpdService'

export function OkpdSelection(props) {
  const [searchInputTimeout, setSearchInputTimeout] = useState();

  useEffect(() => {
    if (props.openOkpdSelectionModal) {
      props.onInitialLoad(props.checkedOkpdCodes);
    }
  }, [props.openOkpdSelectionModal])

  return (
    <React.Fragment>
      <Dialog 
        open={props.openOkpdSelectionModal} 
        onClose={props.onCloseOkpdSelection}
        maxWidth="80%"
        PaperProps={{sx: {
          width: "100%",
          height: "80%"
        }}}
      >
        <Card sx={{overflowY: "scroll"}}>
          <Grid container spacing="0" alignItems="center">
            <Grid item>
              <Typography>
                Поиск по ОКПД:
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant = "outlined"
                size = "small"
                onChange={(event) => {
                    const value = event.target.value;
                    if (value) {
                      if (searchInputTimeout !== null){
                        clearTimeout(searchInputTimeout);
                      }
                      setSearchInputTimeout(setTimeout(() => {
                        if (value.replace(' ','').length > 0) {
                          props.onInputOkpdSearch(value);
                        }
                      }, 500));                           
                    }
                    else {
                      clearTimeout(searchInputTimeout);
                      props.onInitialLoad(props.checkedOkpdCodes)
                    }
                  }
                }
              />
            </Grid>
            <Grid item>
              <FormHelperText error>
                {props.searchWarningMessage}
              </FormHelperText>
            </Grid>
          </Grid>
          {props.isLoading 
            ?
              <CircularProgress/>
            :
              <Tree
                loadData={(node) => props.onChildNodeExpand(node, props.checkedOkpdCodes)}
                checkable
                treeData={props.OkpdCodes}
                checkedKeys={props.checkedOkpdCodes.map(code => { return code.key; })}
                onCheck={props.onSetCheckedOkpdCodes}
                expandedKeys={props.expandedOkpdCodes}
                onExpand={props.onOkpdExpanded}
                showIcon={false}
                className="highlighted"
              />
          }
        </Card>
        <Button onClick={() => props.onOkpdSelected(props.checkedOkpdCodes, props.currentPage)}>Выбрать</Button>
      </Dialog>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    OkpdCodes: state.okpdState.OkpdCodes,
    checkedOkpdCodes: state.okpdState.checkedOkpdCodes,
    openOkpdSelectionModal: state.okpdState.openOkpdSelectionModal,
    expandedOkpdCodes: state.okpdState.expandedOkpdCodes,
    isLoading: state.okpdState.isLoading,
    searchWarningMessage: state.okpdState.searchWarningMessage,
    currentPage: state.pagesState.currentPage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onInitialLoad: (selectedCodes) => {
      let selectedCodeKeys = selectedCodes.map(code => { return code.key });
      OkpdService.getByParent({parentKey: "", selectedCodeKeys: selectedCodeKeys })
        .then((result) => {
          const action = {
            type: 'OKPD_INITIATED',
            OkpdCodes: result
          };
          dispatch(action);
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при получении списка ОКПД',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
    },
    onChildNodeExpand: (node) => {
      //не перезагружаем элемент если ниже есть выбранные узлы
      //if (node.checked !== true && node.halfChecked !== true) {
        return OkpdService.getByParent({ parentKey: node.key, selectedCodeKeys: [] })
        .then(result => {
          const action = {
            type: 'OKPD_EXPAND_CHILD_NODE',
            parentCode: node.key,
            childrenNodes: result
          };
          dispatch(action);
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при получении списка ОКПД',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
      //} else {
      //  return Promise.resolve();
      //}
    },
    onSetCheckedOkpdCodes: (checkedOkpdCodes, event) => {
      if (event.node.pos.length <= 3 && event.node.children.length === 0){
        OkpdService.getByParent({ parentKey: event.node.key, selectedCodeKeys: [] })
        .then(result => {
          const action = {
            type: 'OKPD_CODE_CHECKED',
            checkedOkpdNodesPositions: event.checkedNodesPositions,
            checkedNode: event.node,
            childrenNodes: result.map(code => { return code.key })
          };
          dispatch(action);
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при получении списка ОКПД',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
      } 
      else {
        const action = {
          type: 'OKPD_CODE_CHECKED',
          checkedOkpdNodesPositions: event.checkedNodesPositions,
          checkedNode: event.node,
          childrenNodes: []
        };
        dispatch(action);
      }
    },
    onOkpdSelected: (checkedOkpdCodes, currentPage) => {
      const action = {
        type: 'OKPD_SELECTION_COMPLETED',
        checkedOkpdCodes: checkedOkpdCodes,
        currentPage: currentPage
      };
      dispatch(action);
    },
    onCloseOkpdSelection: () => {
      const action = {
        type: 'OKPD_SELECTION_CLOSED'
      };
      dispatch(action);
    },
    onInputOkpdSearch: (searchText) => {
      const action = {
        type: 'OKPD_SEARCH_STARTED'
      };
      dispatch(action);
      OkpdService.search(searchText)
        .then((result) => {
          const action = {
            type: 'OKPD_SEARCHED',
            searchResults: result,
            searchText: searchText
          };
          dispatch(action);
        })
        .catch((error) => {
          const action = {
            type: 'OKPD_SEARCH_FAILED'
          };
          dispatch(action);
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при поиске по ОКПД',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        })
    },
    onOkpdExpanded: (expandedOkpdCode) => {
      const action = {
        type: 'OKPD_EXPANDED',
        expandedOkpdCode: expandedOkpdCode
      };
      dispatch(action);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OkpdSelection)

