import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../withRouter';
import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

class Menu extends Component {
  constructor(props) {
    super(props);
  }

  transportPurchaseLogsPage = (props) => {
    var isShow = false;

    if (Array.isArray(props.permissions)) {
      if (props.permissions.some(permission => permission.indexOf('admin.unpub') > -1)) {
        isShow = true;
      }
    } else if (typeof props.permissions === 'string') {
      if (props.permissions.indexOf('admin.unpub') > -1) {
        isShow = true;
      }
    }

    if (isShow) {
      return <Link to="/purchases/logs" style={{textDecoration: 'none'}} onClick={() => this.props.onPurchaseTransportLogsPage()}>Перемещение закупок</Link>;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Box>
          <Stack direction="row" spacing={2}>
            <Link to="/purchases" style={{textDecoration: 'none'}} onClick={() => this.props.onPurchasePage()}>Закупки</Link>
            <Link to="/purchases/templates" style={{textDecoration: 'none'}} onClick={() => this.props.onTemplatePage()}>Шаблоны</Link>
            <Link to="/tags" style={{textDecoration: 'none'}} onClick={() => this.props.onTagsPage()}>Избранное</Link>
            <Link to="/organizations" style={{textDecoration: 'none'}} onClick={() => this.props.onOrganizationsPage()}>Компании</Link>
            {this.transportPurchaseLogsPage(this.props)}
          </Stack>
        </Box>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentPage: state.pagesState.currentPage,
    permissions: state.profileState.permissions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onPurchasePage: () => {
      const action = {
        type: 'PURCHASE_PAGE'
      };
      dispatch(action);
    },
    onTemplatePage: () => {
      const action = {
        type: 'TEMPLATE_PAGE'
	  };
      dispatch(action);
    },
    onTagsPage: () => {
      const action = {
        type: 'TAGS_PAGE'
      };
      dispatch(action);
    },
    onOrganizationsPage: () => {
      const action = {
        type: 'ORGANIZATION_PAGE'
      };
      dispatch(action);
    },
    onPurchaseTransportLogsPage: () => {
      const action = {
        type: 'PURCHASE_LOG_PAGE'
      };
      dispatch(action);      
    }
  }
}  
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));