import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import PurchaseItem from './PurchaseItem';
import PurchaseService from '../../services/PurchaseService';

// MUI
import { 
  Box, Stack, Pagination, CircularProgress
} from '@mui/material';

class OrganizationPurchasesAsCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purchases: null,
      pageNumber: 1, 
      pageSize: 20,
      pageCount: 0,
      total: 0,
      isLoading: false
    }

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  componentDidMount() {
    this.handlePaginationChange(this.state.pageNumber);
  }

  handlePaginationChange(event, page) {
    this.setState({isLoading: true});
    PurchaseService.getByOrganizationId({organizationId: this.props.organizationId, iscustomer: true, pageNumber: page, pageSize: this.state.pageSize})
        .then(result => {
          this.setState({
            purchases: result.collections,
            pageNumber: result.pageNumber,
            pageSize: result.pageSize,
            pageCount: result.pageCount,
            isLoading: false
          })
        });
  }

  render() {
    if (this.state.isLoading){
      return (
      <React.Fragment>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </React.Fragment>
      )
    }
    if (this.state.purchases){
    return (
    <React.Fragment>
      {this.state.purchases.map((purchase,key) => 
        <PurchaseItem purchase={purchase} key={key} />
      )}
      {this.state.purchases.length === 0
      ?
      <React.Fragment>
        Закупок нет
      </React.Fragment>
      : null
      }
      {this.state.purchases.length > 0
      ?
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2}>
          <Pagination count={this.state.pageCount ? this.state.pageCount : 1} 
            page={this.state.pageNumber ? this.state.pageNumber : 1} onChange={this.handlePaginationChange} />
        </Stack>
      </Box>
      : null}
    </React.Fragment>
    )}
  }
}

function mapStateToProps(state) {
  return {
    organizationId: state.organizationsState.organization.id,
  }
}

export default connect(mapStateToProps)(withRouter(OrganizationPurchasesAsCustomer));