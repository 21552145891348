import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import OrganizationInfo from './OrganizationInfo';
import OrganizationPurchasesAsCustomer from './OrganizationPurchasesAsCustomer';
import OrganizationPurchasesAsSupplier from './OrganizationPurchasesAsSupplier';
import CustomerReviewsPanel from '../CustomerReviewsPanel';
import CustomerRatingService from '../../services/CustomerRatingService';

// MUI
import { 
  CircularProgress, Box, Grid, Card, CardContent, Tab, Typography, Rating
} from '@mui/material';

import { TabContext, TabList, TabPanel } from '@mui/lab';

class OrganizationResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '0',
      averageRating: 0
    }
  }

  componentDidMount() {
    if (this.props.organization) {
      CustomerRatingService.getCustomerRating(this.props.organization.id)
        .then(rating => {
          this.setState({ averageRating: parseFloat(rating)});
        });
    }
  }

  render() {
    if (this.props.organization)
    {
    return (
      <React.Fragment>
        <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  {this.props.organization.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Rating value={this.state.averageRating} precision={0.1} readOnly />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <TabContext value={this.state.activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(event, newValue) => {
              this.setState({activeTab: newValue})
            }}>
              <Tab label="Общая информация" value='0' />
              <Tab label="Закупки в качестве заказчика" value='1' />
              <Tab label="Закупки в качестве поставщика" value='2' />
              <Tab label="Отзывы" value='3' />
            </TabList>
          </Box>
          <TabPanel value='0'><OrganizationInfo/></TabPanel>
          <TabPanel value='1'><OrganizationPurchasesAsCustomer/></TabPanel>
          <TabPanel value='2'><OrganizationPurchasesAsSupplier/></TabPanel>
          <TabPanel value='3'><CustomerReviewsPanel customerOrganizationId={this.props.organization.id} /></TabPanel>
        </TabContext>
      </React.Fragment>);
    }
    else {
      return (
        <React.Fragment>
          Компания не найдена
        </React.Fragment>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    organization: state.organizationsState.organization
  }
}

export default connect(mapStateToProps)(withRouter(OrganizationResult));