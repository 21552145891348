import React, { Component } from 'react';
import { withRouter } from '../withRouter';
import CustomerService from '../services/CustomerService';
import CustomerRatingService from '../services/CustomerRatingService';
import { connect } from 'react-redux';

// MUI:
import { 
  Card, CardContent, Box, CardHeader, Rating, Typography
} from '@mui/material';

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      averageRating: 0,
      customer: null
    }; 
  }

  componentDidMount() {
    const customerOrganizationId = this.props.customerOrganizationId;
    if (customerOrganizationId) {
      CustomerService.getCustomer(customerOrganizationId)
        .then(customer => { 
          this.setState({ customer: customer}); 
        })
        .catch(error => {
          this.props.onSendError("Не удалось получить данные по компании");
        });
      CustomerRatingService.getCustomerRating(customerOrganizationId)
        .then(rating => {
          this.setState({ averageRating: parseFloat(rating)});
        })
        .catch(error => {
          this.props.onSendError("Не удалось получить данные по оценкам компании");
        });
    }
  }


  render() {
    if (this.state.customer) {
      return (
        <React.Fragment> 
          <Card sx={{ my: 1 }} variant="outlined">
            <CardHeader
              title="Заказчик"
            />
            <CardContent>
              {this.state.customer.name}
            </CardContent>

            {this.state.customer.kpp
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    КПП
                  </Box>
                  <Box>
                    {this.state.customer.kpp}
                  </Box>
                </CardContent>
              </>
            : <></>}    

            {this.state.customer.inn
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    ИНН
                  </Box>
                  <Box>
                    {this.state.customer.inn}
                  </Box>
                </CardContent>
              </>
            : <></>}          

            {this.state.customer.ogrn
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    ОГРН
                  </Box>
                  <Box>
                    {this.state.customer.ogrn}
                  </Box>
                </CardContent>
              </>
            : <></>}

            {this.state.customer.emails
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    Электронная почта
                  </Box>
                  <Box>
                    {this.state.customer.emails.map((email, key) => 
                      <Typography key={key}>{email}</Typography>
                    )}
                  </Box>
                </CardContent>
              </>
            : <></>}             

            {this.state.customer.phones
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    Контактный телефон
                  </Box>
                  <Box>
                    {this.state.customer.phones.map((phone, key) => 
                      <Typography key={key}>{phone}</Typography>
                    )}
                  </Box>
                </CardContent>
              </>
            : <></>}

            {this.state.customer.sourceRegion
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    Регион
                  </Box>
                  <Box>
                    {this.state.customer.sourceRegion}
                  </Box>
                </CardContent>
              </>
            : <></>} 

            {this.state.customer.sourceActualAddress
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    Место нахождения
                  </Box>
                  <Box>
                    {this.state.customer.sourceActualAddress}
                  </Box>
                </CardContent>
              </>
            : <></>}

            {this.state.customer.sourcePostalAddress
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    Почтовый адрес
                  </Box>
                  <Box>
                    {this.state.customer.sourcePostalAddress}
                  </Box>
                </CardContent>
              </>
            : <></>}     

            {this.state.customer.responsiblePerson
            ? <>
                <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    Ответственное лицо
                  </Box>
                  <Box>
                    {this.state.customer.responsiblePerson}
                  </Box>
                </CardContent>
              </>
            : <></>}  

            <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ color: 'text.secondary', mr: 1 }}>
                Средняя оценка
              </Box>
              <Box>
                <Rating value={this.state.averageRating} precision={0.1} readOnly />
              </Box>
            </CardContent>

          </Card>      
          
        </React.Fragment>
      )  
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSendError: (errorMessage) => {
      const notificationAction = {
        type: 'SNACKBAR_NOTIFICATION_SENT',
        notification: {
          message: errorMessage,
          variant: 'error'
        }
      };
      dispatch(notificationAction);
    },
  }
}


export default connect(null, mapDispatchToProps) (withRouter(CustomerDetails));