import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Dialog, CircularProgress } from '@mui/material';
import Tree from 'rc-tree';
import '../css/rc-tree.css';
import IndustriesService from '../services/IndustriesService';

export function IndustrySelection(props) {

  useEffect(() => {
    if (props.openIndustrySelectionModal){
      props.onInitialLoad();
    }
  }, [props.openIndustrySelectionModal])

  return (
    <React.Fragment>
      <Dialog 
        open={props.openIndustrySelectionModal} 
        onClose={props.onCloseIndustrySelection}
        maxWidth="80%"
        PaperProps={{sx: {
          width: "100%",
          height: "80%"
        }}}
      >
        <Card sx={{overflowY: "scroll"}}>
          {props.isLoading 
            ?
              <CircularProgress/>
            :
              <Tree
                checkable
                treeData={props.IndustryCodes}
                checkedKeys={props.checkedIndustryCodes.map(code => { return code.key; })}
                onCheck={props.onSetCheckedIndustryCodes}
                showIcon={false}
                className="highlighted"
              />
          }
        </Card>
        <Button onClick={() => props.onIndustriesSelected(props.checkedIndustryCodes, props.currentPage)}>Выбрать</Button>
      </Dialog>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    IndustryCodes: state.industriesState.IndustryCodes,
    checkedIndustryCodes: state.industriesState.checkedIndustryCodes,
    openIndustrySelectionModal: state.industriesState.openIndustrySelectionModal,
    isLoading: state.industriesState.isLoading,
    searchWarningMessage: state.industriesState.searchWarningMessage,
    currentPage: state.pagesState.currentPage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onInitialLoad: () => {
      IndustriesService.getIndustries()
        .then((result) => {
          const action = {
            type: 'INDUSTRIES_INITIATED',
            IndustryCodes: result
          };
          dispatch(action);
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при получении списка отраслей',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });;
    },
    onSetCheckedIndustryCodes: (checkedIndustryCodes, event) => {
      const action = {
        type: 'INDUSTRY_CODE_CHECKED',
        checkedNode: event.node,
      };
      dispatch(action);
    },
    onIndustriesSelected: (checkedIndustryCodes, currentPage) => {
      const action = {
        type: 'INDUSTRIES_SELECTION_COMPLETED',
        checkedIndustryCodes: checkedIndustryCodes,
        currentPage: currentPage
      };
      dispatch(action);
    },
    onCloseIndustrySelection: () => {
      const action = {
        type: 'INDUSTRIES_SELECTION_CLOSED'
      };
      dispatch(action);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustrySelection)

