import React, { useEffect, useState } from "react"
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Tooltip, Popup, useMapEvents } from 'react-leaflet';
import { useSelector, useDispatch } from "react-redux";
import PurchaseService from "../../services/PurchaseService";
import { Box, List, Button, IconButton, Typography, Card } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import "./assets/leaflet.css";

import { Map } from "../Map/map";
import { Drawer, Grid } from "@mui/material";
import { PurchaseCard } from "./purchase-card";
import MapMarkedPurchasesPanel from "../MapMarkedPurchasesPanel";
//import PurchaseCard from "../PurchaseCard";

export const MapSearchPage = (props) => {

    useEffect(() => {
        const action = {
            type: 'MAP_INITIATED'
        };
        dispatch(action);
        handleSearchGeoClusters();
    },[])

    const dispatch = useDispatch();
    const reduxState = useSelector((state) => {
        return {
            //TODO: сейчас при смене фильтров на странице поиска надо добавлять дополнительно строки сюда, надо бы подумать как унифицировать
            keywords: state.purchasesState.keywords,
            ignoreKeywords: state.purchasesState.ignoreKeywords,
            shouldSearchInDocuments: state.purchasesState.shouldSearchInDocuments,
            shouldSearchInOkpdNames: state.purchasesState.shouldSearchInOkpdNames,
            havePrepayment: state.purchasesState.havePrepayment,
            haveRecommendation: state.purchasesState.haveRecommendation,
            laws: state.purchasesState.laws,
            datePublicationFrom: state.purchasesState.datePublicationFrom,
            datePublicationTo: state.purchasesState.datePublicationTo,
            dateExpirationFrom: state.purchasesState.dateExpirationFrom,
            settlements: state.purchasesState.settlements,
            priceFrom: state.purchasesState.priceFrom,
            priceTo: state.purchasesState.priceTo,
            stages: state.purchasesState.stages,
            kladrCodes: state.purchasesState.kladrCodes,
            industryCodes: state.purchasesState.industryCodes,
            auctionTypes: state.purchasesState.auctionTypes,
            suggestions: state.purchasesState.suggestions,
            settlementsLoading: state.purchasesState.settlementsLoading,
            okpdCodes: state.purchasesState.okpdCodes,
            customers: state.purchasesState.customers,
            shouldIncludeEmptyPrice: state.purchasesState.shouldIncludeEmptyPrice,
            southWestLat: state.mapState.southWestLat,
            southWestLng: state.mapState.southWestLng,
            northEastLat: state.mapState.northEastLat,
            northEastLng: state.mapState.northEastLng,
            zoom: state.mapState.zoom,
            purchaseClusters: state.mapState.purchaseClusters,
            isDrawerOpened: state.mapState.isDrawerOpened,
            purchases: state.mapState.purchases,
            uploadPurchaseIds: state.markedPurchasesState.uploadPurchaseIds
        };
    })


    const handleSearchGeoClusters = (northEastLat, northEastLng, southWestLat, southWestLng, zoom) => {
        PurchaseService.getPurchaseClusters({
            keywords: reduxState.keywords,
            ignoreKeywords: reduxState.ignoreKeywords,
            shouldSearchInDocuments: reduxState.shouldSearchInDocuments,
            shouldSearchInOkpdNames: reduxState.shouldSearchInOkpdNames,
            havePrepayment: reduxState.havePrepayment,
            haveRecommendation: reduxState.haveRecommendation,
            laws: reduxState.laws,
            datePublicationFrom: reduxState.datePublicationFrom,
            datePublicationTo: reduxState.datePublicationTo,
            dateExpirationFrom: reduxState.dateExpirationFrom,
            priceFrom: parseInt(reduxState.priceFrom),
            priceTo: parseInt(reduxState.priceTo),
            stages: reduxState.stages,
            kladrCodes: reduxState.kladrCodes.map(opt => opt.code),
            industryCodes: reduxState.industryCodes.map(opt => opt.key),
            auctionTypes: reduxState.auctionTypes,
            shouldIncludeEmptyPrice: reduxState.shouldIncludeEmptyPrice,
            okpdCodes: reduxState.okpdCodes.flatMap(item => {
              if (item.children.length > 0) {
                return item.children;
              }
              return item.key;
            }),
            customerIds: reduxState.customers.map(opt => opt.id),
            //в elastic границы northWest и southEast, а в leaflet - northEast и southWest, так что получаем одно из другого
            northWestBound: {
                lat: (northEastLat ? northEastLat : reduxState.northEastLat).toString(),
                lon: (southWestLng ? southWestLng : reduxState.southWestLng).toString()
            },
            southEastBound: {
                lat: (southWestLat ? southWestLat : reduxState.southWestLat).toString(),
                lon: (northEastLng ? northEastLng : reduxState.northEastLng).toString()
            },
            zoom: zoom ? zoom : reduxState.zoom
          })
            .then(result => { 
              const action = {
                type: 'MAP_SET_PURCHASE_CLUSTERS',
                purchaseClusters: result
              };
              dispatch(action);
            })
    }

    const handleCloseDrawer = () => {
        const action = {
            type: 'MAP_CLOSE_DRAWER'
        };
        dispatch(action);
    }

    return (
        <React.Fragment>
            <Drawer
                anchor="left"
                open={reduxState.isDrawerOpened}
                variant="persistent"
                PaperProps={{ sx: { width: 1/5 } }}
            >
                <List>
                    <Grid container style={{position: "sticky", top: 0, zIndex: 2, marginBottom: 20, width: '100%'}}>
                        <Box
                            sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1 }}
                        >
                            <Grid item xs={11}>
                                <MapMarkedPurchasesPanel />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={handleCloseDrawer}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Box>
                    </Grid>                    
                {
                    reduxState.purchases
                    ?
                    reduxState.purchases.map((purchase) => 
                        <PurchaseCard purchase={purchase} key={purchase.id} isMarked={reduxState.uploadPurchaseIds.some(uploadPurchaseId => uploadPurchaseId === purchase.id)} />
                    )
                    :
                    null
                }
                {
                    reduxState.purchases.length === 50 
                    ?
                    <Grid container>
                        <Typography variant="subtitle2">
                            *отображаются только 50 первых результатов
                        </Typography>
                    </Grid>
                    : 
                    null
                }
                </List>
            </Drawer>
            <Box>
                <Grid container>
                    <Grid item justifyContent="flex-end" xs={12} sx={{ display: 'flex'}}>
                        <Button variant="contained" component={Link} to="/purchases" sx={{mt: -1, zIndex: 1500}}>
                            Вернуться к фильтру
                        </Button>
                    </Grid>
                    <Grid item style={{width: "100vh"}} xs={12}>
                        <Map purchaseClusters={reduxState.purchaseClusters} onMapSearch={handleSearchGeoClusters}/>
                    </Grid>
                </Grid>
            </Box>
		</React.Fragment>
    )
}
