import React, { Component } from 'react';
import { withRouter } from '../withRouter';
import { connect } from 'react-redux';
import PurchaseTagsService from '../services/PurchaseTagsService';
import { withSnackbar } from 'notistack';

// MUI:
import { 
  Dialog, DialogTitle, List, ListItem, ListItemText, TextField, Button, CircularProgress, Grid, IconButton
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const checkedTagClass = {
  backgroundColor: "#eefff4",
  marginLeft: '24px',
  marginRight: '24px',
  marginBottom: '10px',
  border: 'solid 1px',
  borderColor: "#b6e9c8", 
  width: 'auto'
}

const uncheckedTagClass = {
  backgroundColor: "#f5f5f5",
  marginLeft: '24px',
  marginRight: '24px',
  marginBottom: '10px',
  border: 'solid 1px',
  borderColor: "#dddada", 
  width: 'auto'
}

class SelectTagDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const errorDivs = this.props.newTagValidationErrors.map((err, key) => <Grid item xs={12} sx={{color:'red'}} key={key}>{err}</Grid>);
    return (
      <React.Fragment> 
        <Dialog open={this.props.openSelectTagDialog} onClose={this.props.onClose} >
          <DialogTitle>
            <Grid container direction="row">
              <Grid item xs={11}>
                Выберите папки избранного для данной закупки
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="close" a onClick={this.props.onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>              
            </Grid>
          </DialogTitle>
          <List>
            {
              this.props.isLoading
              ?
                /* TODO эту крутилку заменить на затемнение модалки или что-то типа того? */
                <CircularProgress />
              :
                this.props.tags.map((tag, key) => 
                  (this.props.selectedPurchase.tags && this.props.selectedPurchase.tags.some(e => e.id === tag.id))
                    ? 
                      <ListItem 
                        className='folder_item selected'
                        key={key} 
                        secondaryAction={
                          <CheckIcon />
                        }
                        style={checkedTagClass}
                        onClick={() => this.props.onRemovePurchaseTag(this.props.selectedPurchase.id, tag, this.props.enqueueSnackbar)}
                        >
                        <ListItemText primary={tag.name} />
                      </ListItem>
                    : 
                      <ListItem className='folder_item' 
                        onClick={() => this.props.onAddPurchaseTag(this.props.selectedPurchase.id, tag, this.props.selectedTagId, this.props.enqueueSnackbar)}
                        key={key}
                        style={uncheckedTagClass}
                      >
                        <ListItemText primary={tag.name} />
                      </ListItem>
                )
            }
            <ListItem>
              Добавить новую папку для избранного:
              <TextField 
                size="small"
                onChange={(event) => {
                  var tag = event.target.value;
                  this.props.onNewTagChanged(tag);
                }}
                value={this.props.newTagName}
              />
              <Button 
                variant="contained" 
                onClick={() => this.props.onAddTag(this.props.newTagName, this.props.selectedPurchase.id, this.props.selectedTagId)}
              > +
              </Button>
            </ListItem>
            <ListItem>
              <Grid item xs={12}>
                { errorDivs }
              </Grid>
            </ListItem>
            {/*}
            <ListItem autoFocus button>
              <ListItemText primary="Новая выборка" />
            </ListItem>
              {*/}
          </List>
        </Dialog>
      </React.Fragment>
    )  
  }
}

function mapStateToProps(state) {
  return {
    selectedPurchase: state.selectTagDialogState.selectedPurchase,
    selectedTagId: state.selectTagDialogState.selectedTagId,
    openSelectTagDialog: state.selectTagDialogState.openSelectTagDialog,
    newTagName: state.selectTagDialogState.newTagName,
    tags: state.selectTagDialogState.tags,
    newTagValidationErrors: state.selectTagDialogState.newTagValidationErrors,
    isLoading: state.selectTagDialogState.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onAddTag: (newTagName, purchaseId, oldTagId) => {
      PurchaseTagsService.addTag({name:newTagName})
      .then((response) => {
        if (response && response.status >= 400 && response.errors) {
          const action = {
            type: 'NEW_TAG_ADD_ERROR_OCCURED',
            newTagValidationErrors: response.errors['Name']
          };
          dispatch(action);
        }
        else {
          const newTagId = response;
          if (purchaseId) {
            PurchaseTagsService.setPurchaseTag({ purchaseId: purchaseId, oldTagId: oldTagId, newTagId: newTagId })
              .then(() => {
                const purchaseIds = [purchaseId];
                PurchaseTagsService.getPurchaseTags(purchaseIds)
                  .then(result => {
                    const action = {
                      type: 'REFRESH_PURCHASE_TAGS',
                      purchase: result[0]
                    };
                    dispatch(action);
                  })
              })
            .catch((error) => {
              const notificationAction = {
                type: 'SNACKBAR_NOTIFICATION_SENT',
                notification: {
                  message: 'Произошла ошибка при добавлении закупки в избранное',
                  variant: 'error'
                }
              };
              dispatch(notificationAction);
            })
          } 
        }
      })
    },
    onNewTagChanged: (value) => {
      const action = {
        type: 'NEW_TAG_FIELD_CHANGED',
        newTagName: value
      };
      dispatch(action);
    },
    onAddPurchaseTag: (purchaseId, newTag, oldTagId, enqueueSnackbar) => {
      const action = {
        type: 'SET_IS_LOADING'
      }
      dispatch(action);

      if (purchaseId) {
        PurchaseTagsService.setPurchaseTag({ purchaseId: purchaseId, oldTagId: oldTagId, newTagId: newTag.id })
          .then(() => {
            const action = {
              type: 'TOGGLE_PURCHASE_TAG',
              newTag: newTag
            }
            dispatch(action);
          })
          .catch(error => {
            console.log(error);
            enqueueSnackbar(
              'Произошла ошибка при добавлении закупки в избранное',
              { 
                variant: 'error'
              });
          })
      }
    },
    onRemovePurchaseTag: (purchaseId, newTag, enqueueSnackbar) => {
      const action = {
        type: 'SET_IS_LOADING'
      }
      dispatch(action);

      PurchaseTagsService.removePurchaseTag({purchaseId: purchaseId, tagId: newTag.id})
        .then(() => {
          const action = {
            type: 'TOGGLE_PURCHASE_TAG',
            newTag: newTag
          };
          dispatch(action);
        })
        .catch(() => {
          enqueueSnackbar(
            'Произошла ошибка при удалении закупки из избранного',
            { 
              variant: 'error'
            });
        })
    },
    onClose: () => {
      const action = {
        type: 'CLOSE_TAG_DIALOG'
      };
      dispatch(action);
    }
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectTagDialog)));