import React, { Component } from 'react';
import UserManager from '../../userManager';

//MUI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

class AuthHeader extends Component {

  constructor(props) {
    super(props);
	
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    this.userProfile = user ? user.profile : null;
	
	  this.logout = this.logout.bind(this);
  }
  
  logout() {
	  UserManager.signoutRedirect();
  }
  
  render() {
	return (
		<Stack direction="row" justifyContent="flex-end">
      <Box>
          Вы вошли под именем: {this.userProfile ? this.userProfile.phone_number : ''}
        <Button onClick={this.logout} variant="text" size="small">
          Выйти
        </Button>
      </Box>
		</Stack>
  )}
}

export default AuthHeader;
