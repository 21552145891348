import React, { useMemo } from "react"

export const RegionNameByKladrCode = (props) => {

    const regionMappings = useMemo(() => { 
        return [
        {
            KladrCode: "01",
            Name: "Респ Адыгея"
         },
         {
            KladrCode: "02",
            Name: "Респ Башкортостан"
         },
         {
            KladrCode: "03",
            Name: "Респ Бурятия"
         },
         {
            KladrCode: "04",
            Name: "Респ Алтай"
         },
         {
            KladrCode: "05",
            Name: "Респ Дагестан"
         },
         {
            KladrCode: "06",
            Name: "Респ Ингушетия"
         },
         {
            KladrCode: "07",
            Name: "Кабардино-Балкарская Респ"
         },
         {
            KladrCode: "08",
            Name: "Респ Калмыкия"
         },
         {
            KladrCode: "09",
            Name: "Карачаево-Черкесская Респ"
         },
         {
            KladrCode: "10",
            Name: "Респ Карелия"
         },
         {
            KladrCode: "11",
            Name: "Респ Коми"
         },
         {
            KladrCode: "12",
            Name: "Респ Марий Эл"
         },
         {
            KladrCode: "13",
            Name: "Респ Мордовия"
         },
         {
            KladrCode: "14",
            Name: "Респ Саха /Якутия/"
         },
         {
            KladrCode: "15",
            Name: "Респ Северная Осетия - Алания"
         },
         {
            KladrCode: "16",
            Name: "Респ Татарстан"
         },
         {
            KladrCode: "17",
            Name: "Респ Тыва"
         },
         {
            KladrCode: "18",
            Name: "Удмуртская Респ"
         },
         {
            KladrCode: "19",
            Name: "Респ Хакасия"
         },
         {
            KladrCode: "20",
            Name: "Чеченская Респ"
         },
         {
            KladrCode: "21",
            Name: "Чувашская Республика - Чувашия"
         },
         {
            KladrCode: "22",
            Name: "Алтайский край"
         },
         {
            KladrCode: "23",
            Name: "Краснодарский край"
         },
         {
            KladrCode: "24",
            Name: "Красноярский край"
         },
         {
            KladrCode: "25",
            Name: "Приморский край"
         },
         {
            KladrCode: "26",
            Name: "Ставропольский край"
         },
         {
            KladrCode: "27",
            Name: "Хабаровский край"
         },
         {
            KladrCode: "28",
            Name: "Амурская обл"
         },
         {
            KladrCode: "29",
            Name: "Архангельская обл"
         },
         {
            KladrCode: "30",
            Name: "Астраханская обл"
         },
         {
            KladrCode: "31",
            Name: "Белгородская обл"
         },
         {
            KladrCode: "32",
            Name: "Брянская обл"
         },
         {
            KladrCode: "33",
            Name: "Владимирская обл"
         },
         {
            KladrCode: "34",
            Name: "Волгоградская обл"
         },
         {
            KladrCode: "35",
            Name: "Вологодская обл"
         },
         {
            KladrCode: "36",
            Name: "Воронежская обл"
         },
         {
            KladrCode: "37",
            Name: "Ивановская обл"
         },
         {
            KladrCode: "38",
            Name: "Иркутская обл"
         },
         {
            KladrCode: "39",
            Name: "Калининградская обл"
         },
         {
            KladrCode: "40",
            Name: "Калужская обл"
         },
         {
            KladrCode: "41",
            Name: "Камчатский край"
         },
         {
            KladrCode: "42",
            Name: "Кемеровская область - Кузбасс"
         },
         {
            KladrCode: "43",
            Name: "Кировская обл"
         },
         {
            KladrCode: "44",
            Name: "Костромская обл"
         },
         {
            KladrCode: "45",
            Name: "Курганская обл"
         },
         {
            KladrCode: "46",
            Name: "Курская обл"
         },
         {
            KladrCode: "47",
            Name: "Ленинградская обл"
         },
         {
            KladrCode: "48",
            Name: "Липецкая обл"
         },
         {
            KladrCode: "49",
            Name: "Магаданская обл"
         },
         {
            KladrCode: "50",
            Name: "Московская обл"
         },
         {
            KladrCode: "51",
            Name: "Мурманская обл"
         },
         {
            KladrCode: "52",
            Name: "Нижегородская обл"
         },
         {
            KladrCode: "53",
            Name: "Новгородская обл"
         },
         {
            KladrCode: "54",
            Name: "Новосибирская обл"
         },
         {
            KladrCode: "55",
            Name: "Омская обл"
         },
         {
            KladrCode: "56",
            Name: "Оренбургская обл"
         },
         {
            KladrCode: "57",
            Name: "Орловская обл"
         },
         {
            KladrCode: "58",
            Name: "Пензенская обл"
         },
         {
            KladrCode: "59",
            Name: "Пермский край"
         },
         {
            KladrCode: "60",
            Name: "Псковская обл"
         },
         {
            KladrCode: "61",
            Name: "Ростовская обл"
         },
         {
            KladrCode: "62",
            Name: "Рязанская обл"
         },
         {
            KladrCode: "63",
            Name: "Самарская обл"
         },
         {
            KladrCode: "64",
            Name: "Саратовская обл"
         },
         {
            KladrCode: "65",
            Name: "Сахалинская обл"
         },
         {
            KladrCode: "66",
            Name: "Свердловская обл"
         },
         {
            KladrCode: "67",
            Name: "Смоленская обл"
         },
         {
            KladrCode: "68",
            Name: "Тамбовская обл"
         },
         {
            KladrCode: "69",
            Name: "Тверская обл"
         },
         {
            KladrCode: "70",
            Name: "Томская обл"
         },
         {
            KladrCode: "71",
            Name: "Тульская обл"
         },
         {
            KladrCode: "72",
            Name: "Тюменская обл"
         },
         {
            KladrCode: "73",
            Name: "Ульяновская обл"
         },
         {
            KladrCode: "74",
            Name: "Челябинская обл"
         },
         {
            KladrCode: "75",
            Name: "Забайкальский край"
         },
         {
            KladrCode: "76",
            Name: "Ярославская обл"
         },
         {
            KladrCode: "77",
            Name: "г Москва"
         },
         {
            KladrCode: "78",
            Name: "г Санкт-Петербург"
         },
         {
            KladrCode: "79",
            Name: "Еврейская Аобл"
         },
         {
            KladrCode: "83",
            Name: "Ненецкий АО"
         },
         {
            KladrCode: "86",
            Name: "Ханты-Мансийский Автономный округ - Югра"
         },
         {
            KladrCode: "87",
            Name: "Чукотский АО"
         },
         {
            KladrCode: "89",
            Name: "Ямало-Ненецкий АО"
         },
         {
            KladrCode: "90",
            Name: "Запорожская обл"
         },
         {
            KladrCode: "91",
            Name: "Респ Крым"
         },
         {
            KladrCode: "92",
            Name: "г Севастополь"
         },
         {
            KladrCode: "93",
            Name: "Респ Донецкая Народная"
         },
         {
            KladrCode: "94",
            Name: "Респ Луганская Народная"
         },
         {
            KladrCode: "95",
            Name: "Херсонская обл"
         },
         {
            KladrCode: "99",
            Name: "г Байконур"
         },
    ]}, []);

    if (!props.kladrCode) {
        return <></>;
    }
    var kladrCode = props.kladrCode.slice(0,2);

    var foundRegion = regionMappings.find(mapping => mapping.KladrCode === kladrCode);

    return (
        <React.Fragment>
            {foundRegion.Name}
        </React.Fragment>
    )
}
