import React from 'react';
import { connect, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Link as routerLink } from 'react-router-dom';

import SettlementService from '../services/SettlementService';
import PurchaseService from '../services/PurchaseService';
import TemplateService from '../services/TemplateService';
import CustomerRatingService from '../services/CustomerRatingService';
import PurchaseTagsService from '../services/PurchaseTagsService';
import OkpdSelection from './OkpdSelection';
import IndustrySelection from './IndustrySelection';
import { CustomDatepicker } from './CustomDatepicker';
import { CustomDatepickerFromTo } from './CustomDatepickerFromTo';
import { CustomerSelection } from './CustomerSelection';
import PurchaseSearchResult from './PurchaseSearchResult';
import FormHelperText from '@mui/material/FormHelperText'

//MUI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { 
  CardContent, Modal, Grid, TextField, 
  Checkbox, FormControlLabel, Autocomplete, 
  FormGroup, Stack, Chip, Link, 
  Tooltip, IconButton, InputAdornment
} from '@mui/material';
import { Box } from '@mui/system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=' '
      isNumericString
      decimalScale={0}
    />
  );
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TemplateCreationModal = (props) => {
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [name, setName] = React.useState(null);
  const dispatch = useDispatch();

  const onSaveTemplate = async (name, state) => {
    return TemplateService.add({
      name: name,
      keywords: state.keywords,
      searchInFoundKeywords: state.searchInFoundKeywords,
      ignoreKeywords: state.ignoreKeywords,
      shouldSearchInDocuments: state.shouldSearchInDocuments,
      shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
      havePrepayment: state.havePrepayment,
      haveRecommendation: state.haveRecommendation,
      laws: state.laws,
      datePublicationFrom: state.datePublicationFrom,
      datePublicationTo: state.datePublicationTo,
      dateExpirationFrom: state.dateExpirationFrom,
      priceFrom: parseInt(state.priceFrom),
      priceTo: parseInt(state.priceTo),
      stages: state.stages,
      kladrCodes: state.kladrCodes,
      auctionTypes: state.auctionTypes,
      okpdCodes: state.okpdCodes.map((code) => {
          return {key: code.key, children: code.children.flatMap((child) => child.key ?? child)}
      }),
      industryCodes: state.industryCodes,
      customers: state.customers,
      shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice
    })
    .then(result => {
      const notificationAction = {
        type: 'SNACKBAR_NOTIFICATION_SENT',
        notification: {
          message: 'Шаблон успешно сохранен',
          variant: 'success'
        }
      };
      dispatch(notificationAction);
      props.onClose();
      setName(null);
    })
    .catch(error => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Произошла ошибка при сохранении шаблона',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
    })
  }

  return (
    <Modal
      open={props.onOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <TextField
          id="templateName"
          variant="outlined"
          fullWidth
          onChange={(event) => {
            var name = event.target.value;
            if (name) {
              setName(name);
            }
            else {
              setName(null);
            }
          }}
        />
        <Button
          onClick={() => {
            onSaveTemplate(name, props.state);
          }}
          variant="outlined"
        >Сохранить</Button>
      </Box>
    </Modal>
  )
}

class PurchaseSearchPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmSaveTemplate: false,
      name: '',
      isNameEmpty: false,
      settlementsInputTimeout: null
    };
    this.handleStageChange = this.handleStageChange.bind(this);
    this.handlePurchaseTypeChange = this.handlePurchaseTypeChange.bind(this);
    this.handleCloseSaveTemplate = this.handleCloseSaveTemplate.bind(this);
    this.handleAuctionTypeChange = this.handleAuctionTypeChange.bind(this);
    this.handlePageSearch = this.handlePageSearch.bind(this);
    this.handleShouldIncludeEmptyPriceChange = this.handleShouldIncludeEmptyPriceChange.bind(this);

    this.searchResultsRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.redirectedFromMap === true) {
      this.props.onSearchPurchases(this.props, 0, false);
    }
  }

  componentDidUpdate(prevProps) {
    //rerun search when sortBy changed
    if (prevProps.sortBy !== this.props.sortBy || prevProps.ascending !== this.props.ascending){
      this.props.onSearchPurchases(this.props, 0, false);
    }
  }

  handlePageSearch(pageNumber){
    this.searchResultsRef.current.scrollIntoView(); //scroll to top of results
    this.props.onSearchPurchases(this.props, pageNumber, false); //(props, currentPageNumber, isNewSearch)
  }

  handleStageChange(event) {
    var isChecked = event.target.checked;
    var value = parseInt(event.target.value);

    if (isChecked) {
      this.props.onAddStage(value);
    } else {
      this.props.onRemoveStage(value);
    }
  }

  handleShouldIncludeEmptyPriceChange(event) {
    var isChecked = event.target.checked;
    this.props.onExistMinimumPrice(isChecked);
  }

  handleAuctionTypeChange(event) {
    var isChecked = event.target.checked;
    var value = parseInt(event.target.value);

    if (isChecked) {
      this.props.onAddAuctionType(value);
    } else {
      this.props.onRemoveAuctionType(value);
    }
  }

  handlePurchaseTypeChange(event) {
    var isChecked = event.target.checked;
    var value = parseInt(event.target.value);

    if (isChecked) {
      this.props.onAddLawChanges(value);
    } else {
      this.props.onRemoveLawChanges(value);
    }
  }

  handleCloseSaveTemplate(event) {
    this.setState({ openConfirmSaveTemplate: false });
  }

  render() {
    return (
      <React.Fragment>

      {/* TODO: Эту модалку отсюда выпилить, т.к. это относится к шаблонам. Она должна быть надстройкой над поисковой панелью */}
      {/* <Modal
        open={this.state.openConfirmSaveTemplate}
        onClose={this.handleCloseSaveTemplate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            id="templateName"
            variant="outlined"
            fullWidth
            onChange={(event) => {
              var name = event.target.value?.trim();
              var isNameEmpty = false;
              if (name.length === 0) {
                isNameEmpty = true;
              }
              this.setState({ isNameEmpty: isNameEmpty, name: name });
            }}
          />
          {this.state.isNameEmpty ? <FormHelperText error>Необходимо указать значение</FormHelperText> : null}
          <Button
            onClick={() => {
              const name = this.state.name?.trim();
              if (name.length === 0) {
                this.setState({ isNameEmpty: true });
                return;
              }
              this.props.onSaveTemplate(name, this.props);
              this.handleCloseSaveTemplate();
              this.setState({ isNameEmpty: false, name: '' });
            }}
            variant="outlined"
          >Сохранить</Button>
        </Box>
      </Modal> */}

      <TemplateCreationModal 
        onOpen={this.state.openConfirmSaveTemplate}
        onClose={this.handleCloseSaveTemplate}
        state={this.props}
      />
      <OkpdSelection />
      <IndustrySelection />
      <CustomerSelection />

      <form onSubmit={(event) => {
          event.preventDefault();
        }
      } >
        <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Ключевые слова
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      multiline={true}
                      maxRows={6}
                      id="keywords"
                      variant="outlined"
                      placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                      fullWidth
                      onChange={(event) => this.props.onKeywordsChanges(event.target.value)}
                      value={this.props.keywords.join(',')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Tooltip 
                              title={
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  { 
                                    " - если введены несколько слов через пробел \r\n"
                                    +" ищутся закупки содержащие их все; \r\n"
                                    +" - альтернативные варианты вводятся через запятую; \r\n"
                                    +" - для поиска по маске используются знаки * и ?; \r\n"
                                    +" - * заменяет любое количество любых символов; \r\n"
                                    +" пример: \r\n"
                                    +" запрос \"газ*\" вернет \"газообразный\", \"газель\" \r\n"
                                    +" запрос \"бе*ый\" вернет \"белый\", \"бешеный\" \r\n"
                                    +" не рекомендуется использовать * в начале слова \r\n"
                                    +" - ? заменяет один любой символ \r\n"
                                    +" пример: \r\n"
                                    +" запрос \"?аска\" вернет \"маска\", \"ласка\" \r\n"
                                    +" запрос \"ию?ь\" вернет \"июль\", \"июнь\" \r\n"
                                  }
                                </div>
                              }>
                              <IconButton size="small">
                                <HelpOutlineIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Ключевые слова для поиска в найденном
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      multiline={true}
                      maxRows={6}
                      id="searchInFoundKeywords"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => { this.props.onSearchInFoundKeywordsChanges(event.target.value); }}
                      value={this.props.searchInFoundKeywords.join(',')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Исключать слова
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      multiline={true}
                      maxRows={6}
                      id="ignoreKeywords"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => { this.props.onIgnoreKeywordsChanges(event.target.value); }}
                      value={this.props.ignoreKeywords.join(',')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  </Grid>
                  <Grid item xs={5}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Искать в приложенных документах"
                        onChange={(event) => this.props.onShouldSearchInDocumentsChanges(event.target.checked)}
                        checked={this.props.shouldSearchInDocuments}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={5}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Искать в наименованиях ОКПД2"
                        onChange={(event) => this.props.onShouldSearchInOkpdNamesChanges(event.target.checked)}
                        checked={this.props.shouldSearchInOkpdNames}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    ОКПД
                  </Grid>
                  <Grid item xs={8}>
                    <Stack direction="row" spacing={1}>
                      { this.props.okpdCodes.map((okpd, key) =>
                          <Chip label={okpd.key} key={key} onDelete={() => this.props.onRemoveSingleOkpdSelection(okpd.key)}/>
                      )} 
                    </Stack>
                    <Stack direction="column" spacing={1} sx={{mt: 1}}>
                      { this.props.industryCodes.map((industry, key) =>
                          <Chip label={industry.title} key={key} onDelete={() => this.props.onRemoveSingleIndustrySelection(industry.key)}/>
                      )} 
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <Button onClick={() => this.props.onOpenIndustrySelection(this.props.industryCodes)}>Выбор отраслей</Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button onClick={() => this.props.onOpenOkpdSelection(this.props.okpdCodes)}>Выбор ОКПД</Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Регион поставки
                  </Grid>
                  <Grid item xs={10}>
                    <Autocomplete
                      freeSolo
                      multiple
                      clearOnBlur
                      forcePopupIcon={false}
                      autoHighlight
                      id="checkboxes-tags-demo"
                      options={this.props.settlements}
                      filterOptions={(options) => options}
                      renderTags={(values, getTagProps) => 
                        values.map((value, key) => 
                          <Tooltip 
                            title={value.fullRegionName}
                            key={key}
                          >
                            <Chip
                              {...getTagProps({ key })}
                              label={`${value.shortObjectType} ${value.name}`}
                            />
                          </Tooltip>
                        )
                      }
                      getOptionLabel={(option) => option.shortObjectType + ' ' + option.name }
                      loading = {this.props.settlementsLoading}
                      renderOption={(props, option, { selected }) => {
                        if (!this.props.kladrCodes.some((kladrCode) => kladrCode.code === option.code)) {
                          if (option.fullRegionName) {
                            return (<li {...props} key={option.code}>
                              {option.shortObjectType + ' ' + option.name + ' '}
                              <p style={{ color: "#aeb3b7" }}>
                                {'\u00a0' + option.fullRegionName}
                              </p>
                            </li>)
                          } else {
                            return (<li {...props} key={option.code}>
                              <p>
                                {option.shortObjectType + ' ' + option.name + ' '}
                              </p>
                            </li>)
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Область, округ, республика, край, город, деревня, село"
                          onChange={(event) => {
                            const value = event.target.value;
                            if (value) {
                              if (this.state.settlementsInputTimeout !== null){
                                clearTimeout(this.state.settlementsInputTimeout);
                              }
                              this.setState( { settlementsInputTimeout: setTimeout(() => {
                                  this.props.onSearchSettlements(value);
                                }, 500)
                              });                              
                            }
                            else {
                              clearTimeout(this.state.settlementsInputTimeout);
                              this.props.onClearSettlements();
                            }
                          }}
                          onKeyDown={(event) => {
                              if (event.key === 'Enter' && this.props.settlements.length === 0) {
                                event.stopPropagation();
                              }
                            }
                          }
                        />
                      )}
                      onChange={(event, options) => {
                        this.props.onKladrCodesChanges(options);
                      }}
                      onClose={(event, reason) => {
                        this.props.onClearSettlements();
                      }}
                      value={this.props.kladrCodes}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Этап
                  </Grid>
                  <Grid item xs={10}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Подача заявок"
                        sx={{ width: 180 }}
                        onChange={this.handleStageChange}
                        value={1}
                        checked={this.props.stages.some((stage) => stage === 1)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Работа комиссии"
                        sx={{ width: 180 }}
                        onChange={this.handleStageChange}
                        value={2}
                        checked={this.props.stages.some((stage) => stage === 2)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Завершены"
                        sx={{ width: 180 }}
                        onChange={this.handleStageChange}
                        value={3}
                        checked={this.props.stages.some((stage) => stage === 3)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Отменены"
                        sx={{ width: 180 }}
                        onChange={this.handleStageChange}
                        value={4}
                        checked={this.props.stages.some((stage) => stage === 4)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Не состоялись"
                        sx={{ width: 180 }}
                        onChange={this.handleStageChange}
                        value={5}
                        checked={this.props.stages.some((stage) => stage === 5)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Планируются"
                        sx={{ width: 180 }}
                        onChange={this.handleStageChange}
                        value={6}
                        checked={this.props.stages.some((stage) => stage === 6)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="На исполнении"
                        sx={{ width: 180 }}
                        onChange={this.handleStageChange}
                        value={7}
                        checked={this.props.stages.some((stage) => stage === 7)}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Метод определения поставщика
                  </Grid>
                  <Grid item xs={10}>
                    <FormGroup sx={{  display: 'block', flexDirection: 'column' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        sx={{ display: 'block'}}
                        label="Аукцион"
                        onChange={this.handleAuctionTypeChange}
                        value={1}
                        checked={this.props.auctionTypes.some((auctionType) => auctionType === 1)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        sx={{ display: 'block'}}
                        label="Запрос предложений"
                        onChange={this.handleAuctionTypeChange}
                        value={2}
                        checked={this.props.auctionTypes.some((auctionType) => auctionType === 2)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        sx={{ display: 'block'}}
                        label="Запрос котировок"
                        onChange={this.handleAuctionTypeChange}
                        value={3}
                        checked={this.props.auctionTypes.some((auctionType) => auctionType === 3)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        sx={{ display: 'block'}}
                        label="Конкурс"
                        onChange={this.handleAuctionTypeChange}
                        value={4}
                        checked={this.props.auctionTypes.some((auctionType) => auctionType === 4)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        sx={{ display: 'block'}}
                        label="Закупка у единственного поставщика"
                        onChange={this.handleAuctionTypeChange}
                        value={5}
                        checked={this.props.auctionTypes.some((auctionType) => auctionType === 5)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        sx={{ display: 'block'}}
                        label="Закупка иным способом"
                        onChange={this.handleAuctionTypeChange}
                        value={6}
                        checked={this.props.auctionTypes.some((auctionType) => auctionType === 6)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        sx={{ display: 'block'}}
                        label="Предварительный квалификационный отбор"
                        onChange={this.handleAuctionTypeChange}
                        value={7}
                        checked={this.props.auctionTypes.some((auctionType) => auctionType === 7)}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Тип торгов
                  </Grid>
                  <Grid item xs={10}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="44-ФЗ"
                        sx={{ width: 180 }}
                        onChange={this.handlePurchaseTypeChange}
                        value={1}
                        checked={this.props.laws.some((auctionType) => auctionType === 1)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="223-ФЗ"
                        sx={{ width: 180 }}
                        onChange={this.handlePurchaseTypeChange}
                        value={2}
                        checked={this.props.laws.some((auctionType) => auctionType === 2)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Коммерческие"
                        sx={{ width: 180 }}
                        onChange={this.handlePurchaseTypeChange}
                        value={3}
                        checked={this.props.laws.some((auctionType) => auctionType === 3)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="ПП РФ 615"
                        sx={{ width: 180 }}
                        onChange={this.handlePurchaseTypeChange}
                        value={4}
                        checked={this.props.laws.some((auctionType) => auctionType === 4)}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Наличие аванса
                  </Grid>
                  <Grid item xs={10}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label=""
                        sx={{ width: 180 }}
                        onChange={(event) => this.props.onHavePrepaymentChanges(event.target.checked)}
                        checked={this.props.havePrepayment}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Рекомендация "Эксперт Центр"
                  </Grid>
                  <Grid item xs={10}>
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={(event) => this.props.onHaveRecommendationChanges(event.target.checked)}
                      checked={this.props.haveRecommendation}
                    />
                    <Tooltip title="Прогнозируемое снижение менее 5%">
                      <IconButton sx={{ ml: -3 }} size="small">
                        <HelpOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Начальная цена
                  </Grid>
                  <Grid item xs={5} sx={{ display: 'flex' }}>
                    <TextField
                      value={this.props.priceFrom ? this.props.priceFrom : ''}
                      onChange={(event) => {
                        const priceFrom = event.target.value;
                        this.props.onPriceFromChanges(priceFrom);
                      }}
                      name="numberformat"
                      placeholder="0"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      variant="outlined"
                    />
                    <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                      –
                    </Box>
                    <TextField
                      value={this.props.priceTo ? this.props.priceTo : ''}
                      onChange={(event) => {
                        const priceTo = event.target.value;
                        this.props.onPriceToChanges(priceTo);
                      }}
                      name="numberformat"
                      placeholder="0"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      variant="outlined"
                    />
                    <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                      ₽
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Включая закупки без НМЦ"
                        sx={{ width: 250 }}
                        onChange={this.handleShouldIncludeEmptyPriceChange}
                        // value={1}
                        checked={this.props.shouldIncludeEmptyPrice}
                      />
                    </FormGroup>
                  </Grid>                  
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Опубликовано
                  </Grid>
                  <Grid item xs={10} sx={{ display: 'flex' }}>
                    <CustomDatepickerFromTo
                      dateFrom={this.props.datePublicationFrom}
                      onDateFromChanges={this.props.onDatePublicationFromChanges}
                      dateTo={this.props.datePublicationTo}
                      onDateToChanges={this.props.onDatePublicationToChanges}
                    />
                  </Grid>            
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Дата окончания подачи заявок не раньше чем
                  </Grid>
                  <Grid item xs={10} sx={{ display: 'flex' }}>
                    <CustomDatepicker
                      date={this.props.dateExpirationFrom}
                      onDateChanges={this.props.onDateExpirationFromChanges}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Заказчик
                    </Grid>
                    <Grid item container xs={10} sx={{ display: 'flex' }}>
                      {
                        this.props.customers.length > 0
                        ?
                        <Grid item xs={12}>
                          <Chip label={ `Выбрано компаний: ${this.props.customers.length}` } />
                        </Grid>
                        :
                        null
                      }
                      <Grid item xs={12}>
                        <Link onClick={() => this.props.onOpenSearchOrganizations(this.props.customers)}>
                          {
                          this.props.customers.length > 0
                          ?
                          'изменить'
                          :
                          'добавить'
                          }
                        </Link>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => { 
                    this.props.onSearchPurchases(this.props, 0, true);//(props, currentPageNumber, isNewSearch)
                  }}
                  variant="contained"
                  type="submit"
                >Найти</Button>
                <Button
                  component={ routerLink }
                  to="/map-search-page"
                  variant="outlined"
                  onClick={() => this.props.onSearchPurchases(this.props, 0, false)} //(props, currentPageNumber, isNewSearch)
                >
                  Показать на карте
                </Button>
                <Button
                  onClick={() => this.setState({ openConfirmSaveTemplate: true })}
                  variant="outlined"
                >Сохранить как шаблон</Button>
              </Grid>

              <Grid item xs={12} sx={{ mt: -3 }}>
                <Button size="small" onClick={() => this.props.onResetSearch(this.props.currentPage)}>Сбросить поиск</Button>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
        </form>
        <Box sx={{ mt: 2 }} ref={this.searchResultsRef}>
          <PurchaseSearchResult onPageSearch={this.handlePageSearch} />
        </Box>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    keywords: state.purchasesState.keywords,
    searchInFoundKeywords: state.purchasesState.searchInFoundKeywords,
    ignoreKeywords: state.purchasesState.ignoreKeywords,
    shouldSearchInDocuments: state.purchasesState.shouldSearchInDocuments,
    shouldSearchInOkpdNames: state.purchasesState.shouldSearchInOkpdNames,
    havePrepayment: state.purchasesState.havePrepayment,
    haveRecommendation: state.purchasesState.haveRecommendation,
    laws: state.purchasesState.laws,
    datePublicationFrom: state.purchasesState.datePublicationFrom,
    datePublicationTo: state.purchasesState.datePublicationTo,
    dateExpirationFrom: state.purchasesState.dateExpirationFrom,
    settlements: state.purchasesState.settlements,
    priceFrom: state.purchasesState.priceFrom,
    priceTo: state.purchasesState.priceTo,
    stages: state.purchasesState.stages,
    kladrCodes: state.purchasesState.kladrCodes,
    auctionTypes: state.purchasesState.auctionTypes,
    suggestions: state.purchasesState.suggestions,
    settlementsLoading: state.purchasesState.settlementsLoading,
    okpdCodes: state.purchasesState.okpdCodes,
    industryCodes: state.purchasesState.industryCodes,
    customers: state.purchasesState.customers,
    shouldIncludeEmptyPrice: state.purchasesState.shouldIncludeEmptyPrice,
    currentPage: state.pagesState.currentPage,
    redirectedFromMap: state.purchasesState.redirectedFromMap,
    sortBy: state.purchasesState.sortBy,
    ascending: state.purchasesState.ascending
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onResetSearch: (currentPage) => {
      const action = {
        type: 'RESET_SEARCH_PURCHASES',
        currentPage: currentPage
      };
      dispatch(action);
    },
    //isNewSearch - признак того, что проводится новый поиск, а не переход на новую страницу или поиск в поиске
    onSearchPurchases: (state, pageNumber, isNewSearch) => {
      const action = {
        type: 'SEARCH_PURCHASES'
      };
      dispatch(action);

      let okpdCodes = [];
      state.okpdCodes.forEach(async element => {
        if (element.key.length === 1) {
          const childKeys = element.children.map(opt => opt.key);
          childKeys.forEach(child => {
            okpdCodes = okpdCodes.concat(child);
          });
        } else {
          okpdCodes = okpdCodes.concat(element.key);
        }
      });

      PurchaseService.search({
        keywords: state.keywords,
        searchInFoundKeywords: state.searchInFoundKeywords,
        ignoreKeywords: state.ignoreKeywords,
        shouldSearchInDocuments: state.shouldSearchInDocuments,
        shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
        havePrepayment: state.havePrepayment,
        haveRecommendation: state.haveRecommendation,
        laws: state.laws,
        datePublicationFrom: state.datePublicationFrom,
        datePublicationTo: state.datePublicationTo,
        dateExpirationFrom: state.dateExpirationFrom,
        priceFrom: parseInt(state.priceFrom),
        priceTo: parseInt(state.priceTo),
        stages: state.stages,
        kladrCodes: state.kladrCodes.map(opt => opt.code),
        auctionTypes: state.auctionTypes,
        shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
        // okpdCodes: state.okpdCodes.map(opt => {
        //   if (opt.key.length === 1){
            
        //   }
        //   return opt.key;
        // }),
        okpdCodes: okpdCodes,
        industryCodes: state.industryCodes.map(opt => {
          return opt.key;
        }),
        customerIds: state.customers.map(opt => {
          return opt.id;
        }),
        pageNumber: pageNumber,
        sortBy: state.sortBy,
        asc: state.ascending
      })
        .then(result => {     
          var customerOrgIds = result.collections.map(c => c.customerOrganizationId);

          CustomerRatingService.getCustomerRatings(customerOrgIds)
          .then(result => {
            if (result)
            {
              const action = {
                type: 'CUSTOMER_RATINGS_FOUND',
                customerRatings: result
              };
              dispatch(action);
            }
          })
          .catch((error) => {
            const notificationAction = {
              type: 'SNACKBAR_NOTIFICATION_SENT',
              notification: {
                message: 'Произошла ошибка при получении оценок заказчиков',
                variant: 'error'
              }
            };
            dispatch(notificationAction);
          });

          var purchaseIds = result.collections.map(c => c.id);
          PurchaseTagsService.getPurchaseTags(purchaseIds)
          .then(result => {
            const action = {
              type: 'PURCHASE_TAGS_FOUND',
              purchaseTags: result
            };
            dispatch(action);
          })
          .catch((error) => {
            const notificationAction = {
              type: 'SNACKBAR_NOTIFICATION_SENT',
              notification: {
                message: 'Произошла ошибка при получении меток избранного',
                variant: 'error'
              }
            };
            dispatch(notificationAction);
          });

          const action = {
            type: 'PURCHASES_FOUND',
            purchases: result.collections,
            pageNumber: result.pageNumber,
            pageSize: result.pageSize,
            total: result.total,
            pageCount: result.pageCount,
            keywords: state.keywords,
            searchInFoundKeywords: state.searchInFoundKeywords,
            ignoreKeywords: state.ignoreKeywords,
            shouldSearchInDocuments: state.shouldSearchInDocuments,
            shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
            havePrepayment: state.havePrepayment,
            haveRecommendation: state.haveRecommendation,
            laws: state.laws,
            datePublicationFrom: state.datePublicationFrom,
            datePublicationTo: state.datePublicationTo,
            dateExpirationFrom: state.dateExpirationFrom,
            priceFrom: state.priceFrom,
            priceTo: state.priceTo,
            stages: state.stages,
            kladrCodes: state.kladrCodes,
            auctionTypes: state.auctionTypes,
            okpdCodes: state.okpdCodes,
            industryCodes: state.industryCodes,
            customers: state.customers,
            shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
            currentPage: state.currentPage, 
            isNewSearch: isNewSearch
          };
          dispatch(action);
        })
      .catch((error) => {
        const action = {
          type: 'SEARCH_PURCHASES_FAILED'
        };
        dispatch(action);
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Произошла ошибка при поиске',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
      })
    },
    onSearchSettlements: (keyword) => {
      const action = {
        type: 'SETTLEMENTS_SEARCH'
      };
      dispatch(action);
      SettlementService.search(keyword)
        .then(settlements => {
          const action = {
            type: 'SETTLEMENTS_FOUND',
            settlements: settlements
          };
          dispatch(action);
        })
        .catch((error) => {
          const action = {
            type: 'SETTLEMENTS_SEARCH_FAILED'
          };
          dispatch(action);
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при поиске адреса',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        })
    },
    onClearSettlements: () => {
      const action = {
        type: 'SETTLEMENTS_RESET'
      };
      dispatch(action);
    },
    onKeywordsChanges: (keywords) => {
      const action = {
        type: 'KEYWORDS_CHANGES',
        keywords: keywords
      };
      dispatch(action);
    },
    onSearchInFoundKeywordsChanges: (searchInFoundKeywords) => {
      const action = {
        type: 'SEARCH_IN_FOUND_KEYWORDS_CHANGES',
        searchInFoundKeywords: searchInFoundKeywords
      };
      dispatch(action);
    },
    onIgnoreKeywordsChanges: (ignoreKeywords) => {
      const action = {
        type: 'IGNORE_KEYWORDS_CHANGES',
        ignoreKeywords: ignoreKeywords
      };
      dispatch(action);
    },
    onShouldSearchInDocumentsChanges: (value) => {
      const action = {
        type: 'SHOULD_SEARCH_IN_DOCUMENTS_CHANGES',
        shouldSearchInDocuments: value
      };
      dispatch(action);
    },
    onShouldSearchInOkpdNamesChanges: (value) => {
      const action = {
        type: 'SHOULD_SEARCH_IN_OKPD_NAMES_CHANGES',
        shouldSearchInOkpdNames: value
      };
      dispatch(action);
    },
    onKladrCodesChanges: (kladrCodes) => {
      const action = {
        type: 'KLADR_CODES_CHANGES',
        kladrCodes: kladrCodes
      };
      dispatch(action);    
    },
    onAddStage: (stageId) => {
      const action = {
        type: 'ADD_STAGE',
        stageId: stageId
      };  
      dispatch(action);    
    },
    onRemoveStage: (stageId) => {
      const action = {
        type: 'REMOVE_STAGE',
        stageId: stageId
      };
      dispatch(action);
    },   
    onAddAuctionType: (auctionTypeId) => {
      const action = {
        type: 'ADD_AUCTION_TYPE',
        auctionTypeId: auctionTypeId
      };
      dispatch(action);    
    },
    onRemoveAuctionType: (auctionTypeId) => {
      const action = {
        type: 'REMOVE_AUCTION_TYPE',
        auctionTypeId: auctionTypeId
      };
      dispatch(action);
    },
    onPriceFromChanges: (value) => {
      const action = {
        type: 'PRICE_FROM_CHANGES',
        priceFrom: value
      };
      dispatch(action);      
    },
    onPriceToChanges: (value) => {
      const action = {
        type: 'PRICE_TO_CHANGES',
        priceTo: value
      };
      dispatch(action);
    },
    onExistMinimumPrice: (value) => {
      const action = {
        type: 'SHOULD_INCLUDE_EMPTY_PRICE_CHANGES',
        shouldIncludeEmptyPrice: value
      };
      dispatch(action);
    },
    onHavePrepaymentChanges: (value) => {
      const action = {
        type: 'HAVE_PREPAYMENT_CHANGES',
        havePrepayment: value
      };
      dispatch(action);
    },
    onHaveRecommendationChanges: (value) => {
      const action = {
        type: 'HAVE_RECOMMENDATION_CHANGES',
        haveRecommendation: value
      };
      dispatch(action);
    },    
    onDatePublicationFromChanges: (value) => {
      const action = {
        type: 'DATE_PUBLICATION_FROM_CHANGES',
        datePublicationFrom: value
      };
      dispatch(action);
    },
    onDatePublicationToChanges: (value) => {
      const action = {
        type: 'DATE_PUBLICATION_TO_CHANGES',
        datePublicationTo: value
      };
      dispatch(action);
    },
    onDateExpirationFromChanges: (value) => {
      const action = {
        type: 'DATE_EXPIRATION_FROM_CHANGES',
        dateExpirationFrom: value
      };
      dispatch(action);
    },
    onAddLawChanges: (value) => {
      const action = {
        type: 'ADD_LAW_CHANGES',
        lawId: value
      };
      dispatch(action);      
    },
    onRemoveLawChanges: (value) => {
      const action = {
        type: 'REMOVE_LAW_CHANGES',
        lawId: value
      };
      dispatch(action);      
    },
    onSearchSuggestions: (keywords) => {
      PurchaseService.searchSuggestions(keywords)
        .then(suggestions => {
          const action = {
            type: 'SUGGESTIONS_FOUND',
            suggestions: suggestions
          };
          dispatch(action);
        })        
    },
    onOpenOkpdSelection: (checkedOkpdCodes) => {
      const action = {
        type: 'OKPD_SELECTION_OPENED',
        checkedOkpdCodes: checkedOkpdCodes
      };
      dispatch(action);
    },
    onRemoveSingleOkpdSelection: (key) => {
      const action = {
        type: 'SINGLE_SELECTED_OKPD_REMOVED',
        key: key
      };
      dispatch(action);
    },
    onOpenIndustrySelection: (checkedIndustryCodes) => {
      const action = {
        type: 'INDUSTRIES_SELECTION_OPENED',
        checkedIndustryCodes: checkedIndustryCodes
      };
      dispatch(action);
    },
    onRemoveSingleIndustrySelection: (key) => {
      const action = {
        type: 'SINGLE_SELECTED_INDUSTRY_REMOVED',
        key: key
      };
      dispatch(action);
    },
    onOpenSearchOrganizations: (checkedOrganizations) => {
      const action = {
        type: 'CUSTOMER_SELECTION_OPENED',
        checkedOrganizations: checkedOrganizations
      };
      dispatch(action);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSearchPanel);