import React from 'react';
import { withRouter } from '../../withRouter';

//MUI
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, Link } from '@mui/material';

class PurchaseItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={3} sx={{ display: 'flex' }}>
                      Номер закупки:
                    </Grid>
                    <Grid item xs={9}>
                      <Typography component="div">
                        {this.props.purchase.number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={3} sx={{ display: 'flex' }}>
                      Описание закупки:
                    </Grid>
                    <Grid item xs={9}>
                      <Typography component="div">
                        {this.props.purchase.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={3} sx={{ display: 'flex' }}>
                      Цена закупки:
                    </Grid>
                    <Grid item xs={9}>
                      <Typography component="div">
                        {this.props.purchase.price}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={3} sx={{ display: 'flex' }}>
                      Ссылка на закупку:
                    </Grid>
                    <Grid item xs={9}>
                      <Link component="div" href={this.props.purchase.sourceUrl}>
                        {this.props.purchase.sourceUrl}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
        </Card>
      </React.Fragment>
    )
  }
}

export default withRouter(PurchaseItem);
