import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';

// MUI
import { 
  Grid, Card, CardContent, Tab, Typography
} from '@mui/material';

class OrganizationInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.organization)
    {
    return (
      <React.Fragment>
        <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      ИНН:
                    </Grid>
                    <Grid item xs={10}>
                      {this.props.organization.inn}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      КПП:
                    </Grid>
                    <Grid item xs={10}>
                      {this.props.organization.kpp}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      ОГРН:
                    </Grid>
                    <Grid item xs={10}>
                      {this.props.organization.ogrn}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
          {this.props.organization.phones
            ? <>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          Телефон:
                        </Grid>
                        <Grid item xs={10}>
                          {this.props.organization.phones.map((phone, key) => 
                            <Typography key={key}>{phone}</Typography>
                          )}
                        </Grid>
                      </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </>
          : <></>} 
          {this.props.organization.emails
            ? <>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          E-mail:
                        </Grid>
                        <Grid item xs={10}>
                          {this.props.organization.emails.map((email, key) => 
                            <Typography key={key}>{email}</Typography>
                          )}
                        </Grid>
                      </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              </>
          : <></>} 
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Регион:
                    </Grid>
                    <Grid item xs={10}>
                      {this.props.organization.sourceRegion}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Место нахождения:
                    </Grid>
                    <Grid item xs={10}>
                      {this.props.organization.sourceActualAddress}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Почтовый адрес:
                    </Grid>
                    <Grid item xs={10}>
                      {this.props.organization.sourcePostalAddress}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Ответственное лицо:
                    </Grid>
                    <Grid item xs={10}>
                      {this.props.organization.responsiblePerson}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>);
    }
    else {
      return (
      <React.Fragment>
      </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    organization: state.organizationsState.organization
  }
}

export default connect(mapStateToProps)(withRouter(OrganizationInfo));