import React, { Component } from 'react';
import { withRouter } from '../withRouter';
import PurchaseService from '../services/PurchaseService';
import PurchaseTagsService from '../services/PurchaseTagsService';
import { DateTime } from 'luxon';
import CustomerDetails from "../components/CustomerDetails";
import CustomerReviewsPanel from "../components/CustomerReviewsPanel";
import PurchaseLotDetails from "./PurchaseLotDetails";
import SelectTagDialog from './SelectTagDialog';
import { connect } from 'react-redux';
import { DownloadDocumentLink } from './download-document-link'

// MUI:
import { 
  Card, CardContent, Link, Box, CircularProgress, CardHeader, Typography, Stack, Button, Chip
} from '@mui/material';

class PurchaseDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purchase: null,
      isLoading: false
    };    
  }

  componentDidUpdate(prevProps) {
    //rerender when purchase tags changed
     if (this.props.tagDialogSelectedPurchase?.id && this.props.tagDialogSelectedPurchase.id === this.state.purchase?.id && this.props.tagDialogSelectedPurchase.tags !== this.state.purchase.tags) {
       this.setState({ purchase: { ...this.state.purchase, tags: this.props.tagDialogSelectedPurchase.tags } })
     }
  }

  componentDidMount() {
    const purchaseId = this.props.params.purchaseId;
    this.setState({ isLoading: true });
    
    PurchaseService.get(purchaseId)
      .then(purchase => {
        PurchaseTagsService.getPurchaseTags([purchaseId])
          .then((result) => {
            result.forEach((resultPurchase) => {
              if (resultPurchase.id === purchaseId) {
                purchase.tags = resultPurchase.tags;
              }
            })
            this.setState({ purchase: {...purchase}, isLoading: false});    
          })
          .catch(() => {

          })
      })
      .catch(error => {
        this.props.onSendError(error);
        this.setState({ isLoading: false });
      });
  }

  getSource = () => {
    const block = (
      this.state.purchase.sourceUrls
      .filter((url) => {
        if (url.type === 0 || url.type === 1) {
          return true;
        }
        return false;
      })
      .map((url, key) => {
        if (url.type === 1) return (
          <div key={key}>
            <Link rel="noopener noreferrer" target="_blank" href={url.url} underline="hover">
              Ссылка на ЕИС
            </Link>
          </div>
        )
        if (url.type === 0) return (
          <div key={key}>
            <Link rel="noopener noreferrer" target="_blank" href={url.url} underline="hover">
              Ссылка на источник
            </Link>
          </div>
        )
      })
    );

    if (block && block.length > 0) {
      return block;
    }

    if (this.state.purchase.sourceUrl) {
      const link = (
        <Link rel="noopener noreferrer" target="_blank" href={this.state.purchase.sourceUrl} underline="hover">
          {/* {this.state.purchase.source.name} */}
          источник
        </Link>
      );
      return <>Ссылка на {link}</>
    } else {
      return null
    }
  }

  getDocuments = () => {
    if (this.state.purchase.documents.length > 0) {
      return (
        <ul>
        {
          this.state.purchase.documents.map((document, key) => 
            <li key={key}>
              <div>
                {document.fileName}
                <DownloadDocumentLink path={document.pathToFileInStorage} filename={document.fileName}>
                  <Button>скачать</Button>
                </DownloadDocumentLink>
              </div>
            </li>
          )
        }
        </ul>
      )
    }
  }

  getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  } 

  getLaw = (law) => {
    switch(law) {
      case 1:
        return '44-ФЗ';
      case 2:
        return '223-ФЗ';
      case 3:
        return 'Коммерческие';
      case 4: 
        return 'ПП РФ 615';
      default:
        return 'Не определено';
    }    
  }

  getCurrency = (currencyId) => {
    switch(currencyId) {
      case 1:
        return '₽';
      default:
        return '';
    }
  }

  render() {
    if (this.state.purchase) {
      return (
        <React.Fragment>
          <SelectTagDialog />
          <Card variant="outlined">
            <CardHeader
              title="Информация о закупке"
            />
            <CardContent>
              № {this.state.purchase.number} ({this.getLaw(this.state.purchase.law)}) - {this.state.purchase.title}
            </CardContent>
            <CardContent>
              {this.state.purchase.sourcePlatform && this.state.purchase.sourcePlatform.id ? 'Площадка: '+this.state.purchase.sourcePlatform.name+',' : null } {this.state.purchase.methodType}
            </CardContent>
            {this.state.purchase.sourceDeliveryAddress
              ?
              <CardContent>
                  Адрес доставки: {this.state.purchase.sourceDeliveryAddress}
              </CardContent>
              : null
            }
            {this.state.purchase.prepayment > 0 
              ?
                <CardContent>
                    Предоплата: {this.state.purchase.prepayment} %
                </CardContent>
              : null
            }
            {this.state.purchase.price
            ?
              <CardContent>
                Цена: {this.state.purchase.price} {this.getCurrency(this.state.purchase.currency)}
              </CardContent>
            : null
            }
            {this.state.purchase.provisionRequest
              ?
              <CardContent>
                Обеспечение заявки: {this.state.purchase.provisionRequest}
              </CardContent>
              : null
            }
            {this.state.purchase.provisionContract
              ?
              <CardContent>
                Обеспечение контракта: {this.state.purchase.provisionContract}
              </CardContent>
              : null
            }
            {this.state.purchase.provisionWarranty
              ?
              <CardContent>
                Обеспечение гарантийных обязательств: {this.state.purchase.provisionWarranty}
              </CardContent>
              : null
            }            
            <CardContent>
              <Typography>
                Опубликован {this.getFormatDate(this.state.purchase.purchaseCreateAt)}
              </Typography>
              {
                this.state.purchase.purchaseExpirationAt
                ?
                <Typography>
                  Окончание подачи {this.getFormatDate(this.state.purchase.purchaseExpirationAt)}
                </Typography>
                :
                null
              }
              {
                this.state.purchase.biddingDate
                ?
                <Typography>
                  Дата торгов {this.getFormatDate(this.state.purchase.biddingDate)}
                </Typography>
                :
                null
                }   
              <Typography variant="subtitle2">
                *Все даты указаны по московскому времени
              </Typography>           
            </CardContent>            
            <CardContent>
              {this.getSource()}
            </CardContent>
            <CardContent>
              <Stack direction="row" justifyContent='space-between' spacing={1}>
                  <Stack direction="row" justifyContent='flex-start' spacing={1}>
                    <Button
                      variant="outlined"
                      onClick={() => this.props.onPurchaseTagAddCalled(this.state.purchase, this.props.enqueueSnackbar)}
                    >
                      Избранное
                    </Button>
                  </Stack>
                  <Stack marginTop={'10px'} direction="row" justifyContent='space-between' spacing={1}>
                    {this.state.purchase.tags
                    ? this.state.purchase.tags.map((tag, key) =>
                        <Chip label={tag.name} key={key} />
                    )
                    :
                    null
                    }
                  </Stack>
                </Stack>
              </CardContent>
          </Card>

          <Card sx={{ my: 1 }} variant="outlined">
            <CardHeader
              title="Документы"
            />
            <CardContent>
              {this.getDocuments()}
            </CardContent>
          </Card>
          
          <PurchaseLotDetails lots={this.state.purchase.lots} />
          <CustomerDetails customerOrganizationId={this.state.purchase.customerOrganizationId} />
          <CustomerReviewsPanel customerOrganizationId={this.state.purchase.customerOrganizationId}/>
        </React.Fragment>
        //
      )      
    } else if (this.state.isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      );      
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
      tagDialogSelectedPurchase: state.selectTagDialogState.selectedPurchase
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onPurchaseTagAddCalled: (purchase) => {
      PurchaseTagsService.getTags().then(result => {
        const action = {
          type: 'OPEN_TAG_DIALOG',
          selectedPurchase: purchase,
          tags: result
        };
        dispatch(action);
      })
      .catch((error) => {
        //todo
      });
    },
    onSendError: () => {
      const notificationAction = {
        type: 'SNACKBAR_NOTIFICATION_SENT',
        notification: {
          message: 'Не удалось получить информацию по закупке',
          variant: 'error'
        }
      };
      dispatch(notificationAction);
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchaseDetails));