import React, { Component } from 'react';
import PurchaseReportService from '../services/PurchaseReportService';
import { 
	Modal, 
	FormGroup, 
	FormControlLabel, 
	Checkbox, 
	CircularProgress, 
	Box,
	Stack,
	Button,
	Typography
} from '@mui/material';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { withRouter } from '../withRouter';
import { UploadSettings } from '../constants/upload-settings';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

class MarkedPurchasesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenUploadSettingsModal: false,
      isLoadingUploadSettings: false
    };

		this.handleOpenUploadSettingsModal = this.handleOpenUploadSettingsModal.bind(this);
		this.handleConvertToString = this.handleConvertToString.bind(this);
		this.handleCloseUploadSettingsWithoutSaveModal = this.handleCloseUploadSettingsWithoutSaveModal.bind(this);
		this.handleUploadFieldChange = this.handleUploadFieldChange.bind(this);
	}

  handleUploadFieldChange(event) {
    var isChecked = event.target.checked;
    var value = event.target.value;

    if (isChecked) {
      this.props.onAddUploadField(value, this.props.currentPage);
    } else {
      this.props.onRemoveUploadField(value, this.props.currentPage);
    }    
  }

  handleOpenUploadSettingsModal() {
	PurchaseReportService.getSettings()
	.then(settings => {
		//remove non-actual settings
		var filteredSettings = settings.filter(setting => UploadSettings.find(us => us.id == setting));
		this.props.onUploadSettings(filteredSettings, this.props.currentPage);
		this.setState({
			isLoadingUploadSettings: false,
			isOpenUploadSettingsModal: true
		});
	});

    this.setState({
      isLoadingUploadSettings: true
    })
  }	

  handleConvertToString() {
    PurchaseReportService.convertToString(this.props.uploadPurchaseIds)
      .then(str => {
		if (!str) {
			this.props.enqueueSnackbar(
				'Пустой результат',
				{
					variant: 'warning',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
					autoHideDuration: 4000
				}
			);
			return;
		}

        // TODO: Кастомная реализация копирования в буфер. Исправить на использование к API
        const textarea = document.createElement("textarea");
        textarea.textContent = str;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);        

        this.props.enqueueSnackbar(
          'Подборки скопированы в буфер',
          { 
            variant: 'success', 
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            autoHideDuration: 2000
          })
      })
	  .catch(error => {
		this.props.enqueueSnackbar(
			'Произошла ошибка при попытке обработки закупок',
			{
				variant: 'error',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
				autoHideDuration: 4000
			}
		)
	  });
  }

  handleCloseUploadSettingsWithoutSaveModal() {
    this.setState({
      isOpenUploadSettingsModal: false
    })
  }	
	
  handleCloseUploadSettingsWithSaveModal() {
    this.setState({
      isOpenUploadSettingsModal: false
    })

    PurchaseReportService.addOrUpdateSettings(this.props.uploadFields);
  }	

	render() {
		if (this.props.total > 0) {
			return (
				<React.Fragment>
				<div style={{ width: '100%', position: "sticky", top: 0, zIndex: 2 }}>
					<Box
						sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1, border: 1, borderColor: "lightgray" }}
					>
						<Box sx={{ width: '100%' }}>
							{this.props.total > 0 ? "Количество: " + (this.props.total >= 10000 ? '>10000' : this.props.total) : null}
							{this.props.total > 10000 ? <Typography variant="caption" component="div">(отображаются только первые 10000 записей)</Typography> : null}
						</Box>
						<Box sx={{ flexShrink: 0 }}>
							<Stack direction="row" justifyContent='flex-end' spacing={1}>
								{
									this.props.pagePurchaseIds.every(item => this.props.uploadPurchaseIds.includes(item))
									?
										<Button
											variant='contained'
											onClick={() => this.props.onUnmarkMany(this.props.pagePurchaseIds, this.props.currentPage)}
										>
											Убрать все на странице
										</Button>
									:
										<Button
											variant="outlined"
											onClick={() => this.props.onMarkMany(this.props.pagePurchaseIds, this.props.currentPage)}
										>
											Отметить все на странице
										</Button>
								}
								<Button 
									variant="outlined" 
									onClick={() => this.props.onClearUploadPurchases(this.props.currentPage)}
									>
									Очистить отмеченные ({this.props.uploadPurchaseIds.length})
								</Button>
								<Button 
									variant="outlined" 
									onClick={() => this.handleOpenUploadSettingsModal()}
									>
									Настройки
								</Button>
								<Button
										variant="outlined"
										onClick={
											() => 
												this.handleConvertToString()
													
										}
									>
									Копировать в буфер
								</Button>
							</Stack>
						</Box>
					</Box>
				</div>

				<Modal
				open={this.state.isOpenUploadSettingsModal}
				onClose={this.handleOpenUploadSettingsModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
				{(() => {
					if (this.state.isLoadingUploadSettings) {
						return(
							<Box sx={{ display: 'flex' }}>
								<CircularProgress />
							</Box>
						);
					} else {
						return(
							<Box sx={style}>
								<FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
									{
										UploadSettings.map((setting, key) => (
											<FormControlLabel
												control={<Checkbox />}
												label={setting.title}
												onChange={this.handleUploadFieldChange}
												value={setting.id}
												checked={this.props.uploadFields.some((field) => field === setting.id)}
												key={key}
											/>
										))
									}
								</FormGroup>
								<Stack direction="row" spacing={1}>
									<Button 
										variant="contained" 
										onClick={() => this.handleCloseUploadSettingsWithSaveModal() }
									>
										Сохранить
									</Button>
									<Button
										variant="contained" 
										onClick={() => this.handleCloseUploadSettingsWithoutSaveModal() }
									>
										Отмена
									</Button>
								</Stack>
							</Box>
						);
					}
				})()}
				</Modal>	
				</React.Fragment>				
			);
		}
	}
}

function mapStateToProps(state) {
  if (state.pagesState.currentPage === "PURCHASES") {
    return {
			uploadFields: state.markedPurchasesState.uploadFields,
			uploadPurchaseIds: state.markedPurchasesState.uploadPurchaseIds,
			total: state.purchasesState.total,
			currentPage: state.pagesState.currentPage,
			pagePurchaseIds: state.purchasesState.purchases.map((purchase) => purchase.id)
    };
  }
  if (state.pagesState.currentPage === "TEMPLATES") {
    return {
			uploadFields: state.markedTemplatesState.uploadFields,
			uploadPurchaseIds: state.markedTemplatesState.uploadPurchaseIds,
			total: state.templatesState.total,
			currentPage: state.pagesState.currentPage,
			pagePurchaseIds: state.templatesState.purchases.map((purchase) => purchase.id)
    };
  }
  return {
    purchases: [],
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onAddUploadField: (uploadField, currentPage) => {
      const action = {
        type: 'ADD_UPLOAD_FIELD',
        uploadField: uploadField,
        currentPage: currentPage
      }   
      dispatch(action);    
    },
    onRemoveUploadField: (uploadField, currentPage) => {
      const action = {
        type: 'REMOVE_UPLOAD_FIELD',
        uploadField: uploadField,
        currentPage: currentPage
      }
      dispatch(action);
    },
    onClearUploadPurchases: (currentPage) => {
      const action = {
        type: 'CLEAR_UPLOAD_PURCHASES',
        currentPage: currentPage
      }
      dispatch(action);
    },
    onUploadSettings: (settings = [], currentPage) => {
      const action = {
        type: 'UPLOAD_SETTINGS',
        settings: settings,
        currentPage: currentPage
      };
      dispatch(action);
    },
	onMarkMany: (uploadPurchaseIds, currentPage) => {
		const action = {
			type: 'ADD_MANY_UPLOAD_FIELDS',
			uploadPurchaseIds: uploadPurchaseIds,
			currentPage: currentPage
		};
		dispatch(action);
	},
	onUnmarkMany: (uploadPurchaseIds, currentPage) => {
		const action = {
			type: 'REMOVE_MANY_UPLOAD_FIELDS',
			uploadPurchaseIds: uploadPurchaseIds,
			currentPage: currentPage
		};
		dispatch(action);
	}
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(MarkedPurchasesPanel)));