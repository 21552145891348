const initialState = {
  IndustryCodes: [],
  checkedIndustryCodes: [],
  openIndustrySelectionModal: false,
  isLoading: false
}

const reducer = (state = initialState, action) => {
  if (action.type === 'INDUSTRIES_INITIATED') {
    var updatedIndustryCodes = [...action.IndustryCodes];
    return {
      ...state,
      IndustryCodes: updatedIndustryCodes,
      searchWarningMessage: ''
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_OPENED') {
    return {
      ...state,
      checkedIndustryCodes: action.checkedIndustryCodes,
      openIndustrySelectionModal: true
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_CLOSED') {
    return {
      ...state,
      openIndustrySelectionModal: false
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_COMPLETED') {
    return {
      ...state,
      openIndustrySelectionModal: false
    }
  }

  if (action.type === 'INDUSTRY_CODE_CHECKED') {
    let updatedCheckedIndustryCodes;
    if (action.checkedNode.checked === false) {
      let newNode = {
        key: action.checkedNode.key,
        title: action.checkedNode.title
      };
      updatedCheckedIndustryCodes = state.checkedIndustryCodes.concat(newNode);
    }
    if (action.checkedNode.checked === true) {
      const value = action.checkedNode.key;
      const array = state.checkedIndustryCodes.map(code => {
        return code.key;
      });
      updatedCheckedIndustryCodes = [...state.checkedIndustryCodes];
      const index = array.indexOf(value);
      if (index !== -1) {
        updatedCheckedIndustryCodes.splice(index, 1);
      }
    }
    return {
      ...state,
      checkedIndustryCodes: updatedCheckedIndustryCodes
    }
  }

  if (action.type === 'SINGLE_SELECTED_INDUSTRY_REMOVED' || action.type === 'TEMPLATES_SINGLE_SELECTED_INDUSTRY_REMOVED') {
    const value = action.key;
    const array = state.checkedIndustryCodes.map(code => {
      return code.key;
    });
    const index = array.indexOf(value);
    const updatedCheckedIndustryCodes = [...state.checkedIndustryCodes];
    if (index !== -1) {
      updatedCheckedIndustryCodes.splice(index, 1);
      return {
        ...state,
        checkedIndustryCodes: updatedCheckedIndustryCodes
      }
    }
  }

  return state;
}

export default reducer;