import React from 'react';
import { connect } from 'react-redux';
import PurchaseService from '../services/PurchaseService';
import PurchaseTagsService from '../services/PurchaseTagsService';
import SelectTagDialog from '../components/SelectTagDialog';
import { DateTime } from 'luxon';

//MUI
import { Box, Button, Card, CardContent, Grid, Link, Typography, Stack, Dialog, Rating, TextField, CircularProgress, Pagination } from '@mui/material';

const dialogInputStyle = {
  width: "500px",
  margin: "5px"
};

class Tags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addTagName: ''
    }; 
  }

  componentDidMount() {
    this.props.onUpdateTags();
  }

  componentDidUpdate(prevProps) {
    //if changed tags in selectedPurchase and dont changed it's id - then we must rerender page
    if (prevProps.tagDialogSelectedPurchase.tags !== this.props.tagDialogSelectedPurchase.tags 
      && prevProps.tagDialogSelectedPurchase.id === this.props.tagDialogSelectedPurchase.id){
      this.props.onGetPurchases(this.props.selectedTagId, this.props.pageSize, this.props.pageNumber);
    }
  }

  getSourcePlatform = (purchase) => {
    if (purchase.sourcePlatform) {
      return purchase.sourcePlatform.name;
    } else {
      return null
    }
  }  

  getLaw = (purchase) => {
    switch(purchase.law) {
      case 1:
        return '44-ФЗ';
      case 2:
        return '223-ФЗ';
      case 3:
        return 'Коммерческие';
      default:
        return 'Не определено';
    }    
  }

  getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  }  

  getStage = (purchase) => {
    switch(purchase.stage) {
      case 1:
        return 'Подача заявок ' + this.getFormatDate(purchase.purchaseExpirationAt);
      case 2:
        return 'Работа комиссии';
      case 3:
        return 'Размещение завершено';
      case 4:
        return 'Размещение отменено';
      case 5:
        return 'Торги не состоялись';
      case 6: 
        return 'Торги запланированы';
      case 7: 
        return 'На исполнении';
      default:
        return 'Не определено';
    }
  }

  render() {
    return (
      <React.Fragment>
        <SelectTagDialog />
        <Dialog open={this.props.editTagDialogOpened} onClose={this.props.onCancelEditDialog}>
          <Grid>
            <Grid>
              <Typography sx={{m:1}}>Название папки</Typography>
            </Grid>
            <Grid>
              <TextField 
                defaultValue={this.props.editTag.name}
                onChange={(event) => {this.props.onEditTagName(event.target.value)}}
                sx={dialogInputStyle}
                />
            </Grid>
            { this.props.editTagDialogErrors.map((err, key) => <Grid item xs={12} sx={{color:'red', m:1}} key={key}>*{err}</Grid>) }
          </Grid>
          <Grid>
            <Button onClick={() => this.props.onSaveEditDialog(this.props.editTag, this.props.editTagId)}>Сохранить</Button>
            <Button onClick={() => this.props.onCancelEditDialog()}>Отмена</Button>
          </Grid>
        </Dialog>
        {this.props.isTagsLoading 
        ?
          <CircularProgress />
        :
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              // flexDirection: 'row',
              p: 1,
              m: 1,
              // bgcolor: 'background.paper',
              // borderRadius: 1,
            }}      
          >
            {this.props.tags
              ? this.props.tags.map((tag, key) => {
                  let variant = tag.id === this.props.selectedTagId ? "contained" : "outlined";
                  return <Box sx={{ p: 0.5 }} key={key}>
                      <Button variant={variant} onClick={() => this.props.onGetPurchases(tag.id, this.props.pageSize, 1)}>{tag.name}</Button>
                    </Box>   
                }      
              )
              :
              null
            } 
            <Box sx={{ p: 0.5 }}>
              <Button variant="contained" color="success" onClick={() => this.props.onOpenEditTagDialog()}>+</Button> 
            </Box>
          </Box>
        }
        {
          this.props.selectedTagId
          ?
            <Grid>
              <Button 
                
                onClick={() => this.props.onOpenEditTagDialog(this.props.selectedTagId) }
              >
                Редактировать</Button>
              <Button 
                
                color="error"
                onClick={() => this.props.onRemovingTag(this.props.selectedTagId)}
              >
                Удалить</Button>
            </Grid>
          : 
            null
        }
        {this.props.isTagPurchasesLoading
          ? 
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          :
            null
        }
        {
          this.props.purchases.length > 0 
          ?
          this.props.purchases.map((purchase, key) =>
            <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }} key={key}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={7}>
                    <Link rel="noopener noreferrer" target="_blank" href={"/purchases/" + purchase.id} underline="hover" onChange={() => this.props.navigate(`/purchases/${purchase.id}`)}>
                      {purchase.title}
                    </Link>
                    <Typography variant="caption" sx={{ pt: 3 }} color="text.secondary" component="div">
                      {[this.getLaw(purchase), purchase.methodType, purchase.number].filter(Boolean).join(', ')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1" component="div">
                      {purchase.price ? purchase.price + ' ₽' : '—'}
                    </Typography>
                    {this.getSourcePlatform(purchase)}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1" component="div">
                      {this.getStage(purchase)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Stack direction="row" justifyContent='flex-end'>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      this.props.onPurchaseTagChangeCalled(purchase, this.props.selectedTagId);
                    }}
                    >
                    Сменить папку
                  </Button>
                  
                  <Button 
                    variant="outlined" 
                    size="small"
                    color="error"
                    onClick={() => {
                      this.props.onRemovingPurchaseTag({purchaseId: purchase.id, tagId: this.props.selectedTagId})
                      .then(() => {
                        this.props.onGetPurchases(this.props.selectedTagId, this.props.pageSize, this.props.pageNumber);
                      });
                    }}
                    >
                    Убрать закупку
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          )
          : 
          null
        }

        {this.props.pageCount > 0
        ?
          <Box sx={{ mt: 2 }}>
              <Stack spacing={2}>
                  <Pagination page={this.props.pageNumber} count={this.props.pageCount} onChange={(e, pageNumber) => this.props.onGetPurchases(this.props.selectedTagId, this.props.pageSize, pageNumber)} />
              </Stack>
          </Box>
        :
          null
        }

      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    tags: state.tagsState.tags,
    purchases: state.tagsState.purchases,
    selectedTagId: state.tagsState.selectedTagId,
    selectedTag: state.tagsState.selectedTag,
    pageSize: state.tagsState.pageSize,
    pageNumber: state.tagsState.pageNumber,
    pageCount: state.tagsState.pageCount,
    editTagDialogOpened: state.tagsState.editTagDialogOpened,
    editTagDialogErrors: state.tagsState.editTagDialogErrors,
    editTag: state.tagsState.editTag,
    editTagId: state.tagsState.editTagId,
    isTagPurchasesLoading: state.tagsState.isTagPurchasesLoading,
    isTagsLoading: state.tagsState.isTagsLoading,
    selectedPurchase: state.selectTagDialogState.selectedPurchase,
    tagDialogSelectedPurchase: state.selectTagDialogState.selectedPurchase
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onOpenEditTagDialog: (tagId) => {
      if (tagId){
        PurchaseTagsService.getTag(tagId)
          .then((tag) => {
            const action = {
              type: 'EDIT_DIALOG_OPEN',
              editTag: tag,
              editTagId: tagId
            };
            dispatch(action);
          })
          .catch((error) => {
            const notificationAction = {
              type: 'SNACKBAR_NOTIFICATION_SENT',
              notification: {
                message: 'Не удалось получить данные по папке избранного',
                variant: 'error'
              }
            };
            dispatch(notificationAction);
          });
      } else {
        const action = {
          type: 'EDIT_DIALOG_OPEN',
          editTag: {},
          editTagId: null
        };
        dispatch(action);
      }
    },
    onSaveEditDialog: (tag, tagId) => {
      if (tagId) {
      PurchaseTagsService.updateTag(tagId, tag)
        .then((response) => {
          if (response && response.status >= 400 && response.errors) {
            const action = {
              type: 'EDIT_DIALOG_ERROR',
              errors: response.errors['Name']
            };
            dispatch(action);
          }
          else {
            const notificationAction = {
              type: 'SNACKBAR_NOTIFICATION_SENT',
              notification: {
                message: 'Папка избранного успешно сохранена',
                variant: 'success'
              }
            };
            dispatch(notificationAction);

            const action = {
              type: 'EDIT_DIALOG_SAVE',
              tag: tag,
              tagId: tagId
            };
            dispatch(action);
          }
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при редактировании папки избранного',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
      } else {
        PurchaseTagsService.addTag(tag)
          .then((response) => {
            if (response && response.status >= 400 && response.errors) {
              const action = {
                type: 'EDIT_DIALOG_ERROR',
                errors: response.errors['Name']
              };
              dispatch(action);
            }
            else {
              const notificationAction = {
                type: 'SNACKBAR_NOTIFICATION_SENT',
                notification: {
                  message: 'Папка избранного успешно сохранена',
                  variant: 'success'
                }
              };
              dispatch(notificationAction);

              const action = {
                type: 'ADD_DIALOG_SAVE',
                tag: {id: response, name: tag.name}
              };
              dispatch(action);
            }
          })
          .catch((error) => {
            const notificationAction = {
              type: 'SNACKBAR_NOTIFICATION_SENT',
              notification: {
                message: 'Произошла ошибка при добавлении папки избранного',
                variant: 'error'
              }
            };
            dispatch(notificationAction);
          });
      }
    },
    onCancelEditDialog: () => {
      const action = {
        type: 'EDIT_DIALOG_CANCEL'
      };
      dispatch(action);
    },
    onEditTagName: (value) => {
      const action = {
        type: 'TAG_NAME_EDIT',
        tagName: value
      };
      dispatch(action);
    },
    onUpdateTags: () => {
      const action = {
        type: 'GET_TAGS_STARTED'
      };
      dispatch(action);

      PurchaseTagsService.getTags()
      .then(result => {
        const action = {
          type: 'GET_TAGS_FINISHED',
          tags: result
        };
        dispatch(action);
      })
      .catch((error) => {
        const action = {
          type: 'GET_TAGS_ERROR'
        };
        dispatch(action);

        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Не удалось получить папки избранного',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
      })
    },
    onGetPurchases: (tagId, pageSize, pageNumber) => {
      const action = {
        type: 'TAG_SELECTED'
      };
      dispatch(action);

      PurchaseTagsService.getPurchasesForTag(tagId, pageSize, pageNumber)
      .then(result => {
        if (result) {
          //if result have purchases and page have not - render previous page
          if (result.total > 0 && result.collections.length === 0) {
            PurchaseTagsService.getPurchasesForTag(tagId, pageSize, pageNumber-1)
            .then((result) => {
              PurchaseService.getMany(result.collections)
                .then(purchases => {
                  const action = {
                    type: 'PURCHASES_FOR_TAG_FOUND',
                    purchases: purchases,
                    selectedTagId: tagId,
                    pageCount: result.pageCount,
                    pageNumber: pageNumber
                  };
                  dispatch(action);
                });
            })
            return;
          }
          PurchaseService.getMany(result.collections)
            .then(purchases => {
              const action = {
                type: 'PURCHASES_FOR_TAG_FOUND',
                purchases: purchases,
                selectedTagId: tagId,
                pageCount: result.pageCount,
                pageNumber: pageNumber
              };
              dispatch(action);
            });
        }
        else {
          const action = {
            type: 'PURCHASES_FOR_TAG_FOUND',
            purchases: [],
            total: 0,
            pageNumber: 1,
            selectedTagId: tagId
          };
          dispatch(action);
        }
      })
      .catch(error => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Произошла ошибка при получении закупок в папке избранного',
            variant: 'error'
          }
        };
        dispatch(notificationAction);

        const action = {
          type: 'ERROR_WHILE_GET_PURCHASES_FOR_TAG'
        };
        dispatch(action);
      });
    },
    onRemovingPurchaseTag: async (purchaseTag) => {
      return PurchaseTagsService.removePurchaseTag(purchaseTag)
        .then(result => {
          const action = {
            type: 'PURCHASE_TAG_REMOVED',
            purchaseId: purchaseTag.purchaseId
          };
          dispatch(action);
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Ошибка при удалении закупки из избранного',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
    },
    onRemovingTag: (tagId) => {
      PurchaseTagsService.removeTag(tagId)
        .then(result => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Папка избранного успешно удалена',
              variant: 'success'
            }
          };
          dispatch(notificationAction);

          const action = {
            type: 'TAG_REMOVED',
            tagId: tagId
          };
          dispatch(action);
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Ошибка при удалении папки избранного',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
    },
    onPurchaseTagChangeCalled: (purchase, tagId) => {
      const purchaseIds = [purchase.id];
      PurchaseTagsService.getPurchaseTags(purchaseIds)
      .then((result) => {
        purchase.tags = result[0].tags;
        PurchaseTagsService.getTags()
        .then(result => {
          const action = {
            type: 'OPEN_TAG_DIALOG',
            selectedPurchase: purchase,
            //selectedTagId: tagId,
            tags: result
          };
          dispatch(action);
      })
      .catch((error) => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Не удалось получить папки избранного',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
      })
    })
    .catch((error) => {
      const notificationAction = {
        type: 'SNACKBAR_NOTIFICATION_SENT',
        notification: {
          message: 'Не удалось получить папки избранного',
          variant: 'error'
        }
      };
      dispatch(notificationAction);
    });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags);