import React, { Component } from 'react';
import { withRouter } from '../withRouter';

// MUI:
import { 
  Card, CardContent, Box, CardHeader, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, Typography
} from '@mui/material';

class PurchaseLotDetails extends Component {
  render() {
      return (
        <React.Fragment>
          <Card sx={{ my: 1 }} variant="outlined">
            <CardHeader
              title="Список лотов"
            />
            <CardContent>
              <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Наименование</TableCell>
                        <TableCell style={{ width:"30%" }}>ОКПД</TableCell>
                        <TableCell style={{ width:"10%" }}>Стоимость</TableCell>
                        <TableCell style={{ width:"10%" }}>Количество</TableCell>
                      </TableRow>
                    </TableHead>
                      {this.props.lots.map((lot, key) =>
                        <TableBody key={key}>
                          <TableRow sx={{ "& td": { border: 0 } }}>
                            <TableCell>{lot.title}</TableCell>
                            <TableCell>
                              {lot.okpdCodes.map((okpd, key) => 
                                <div key={key}>
                                  {okpd.code} {okpd.name}
                                </div>
                              )}
                              {lot.ktruCodes.map((ktru, key) => 
                                <div key={key}>
                                  {ktru.code} {ktru.name}
                                </div>
                              )}
                              {lot.okpdCode} {lot.okpdName} {lot.ktruCode} {lot.ktruName}
                              </TableCell>
                            <TableCell>{lot.price ? lot.price + ' ₽' : '—'}</TableCell>
                            <TableCell>{lot.quantity}</TableCell>
                          </TableRow>
                          {lot.lotItemsType === 1 
                            ?
                              <React.Fragment>
                                <TableRow sx={{ "& td": { border: 0 } }}>
                                  <TableCell colSpan={4}>
                                    <Box>
                                      <Typography variant="caption" component="div" align="center">
                                        <b>Составляющие лота:</b>
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                {lot.lotItems.map((lotItem, key) =>
                                <TableRow key={key} sx={{ "& td": { border: 0 } }}>
                                  <TableCell>{lotItem.title}</TableCell>
                                  <TableCell>
                                    {lotItem.okpdCodes.map((okpd, key) => 
                                      <div key={key}>
                                        {okpd.code} {okpd.name}
                                      </div>
                                    )}
                                    {lotItem.ktruCodes.map((ktru, key) => 
                                      <div key={key}>
                                        {ktru.code} {ktru.name}
                                      </div>
                                    )}
                                    {lotItem.okpdCode} {lotItem.okpdName} {lotItem.ktruCode} {lotItem.ktruName}
                                    </TableCell>
                                  <TableCell>{lotItem.price ? lotItem.price + ' ₽' : '—'}</TableCell>
                                  <TableCell>{lotItem.quantity}</TableCell>
                                </TableRow>
                                )}
                              </React.Fragment>
                            : null
                          }
                          {lot.lotItemsType === 2
                            ?
                              <React.Fragment>
                                <TableRow sx={{ "& td": { border: 0 } }}>
                                  <TableCell colSpan={4}>
                                    <Box>
                                      <Typography variant="caption" component="div" align="center">
                                        <b>Альтернативы лота:</b>
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                {
                                lot.lotItems.map((lotItem, key) =>
                                  <TableRow key={key} sx={{ "& td": { border: 0 } }}>
                                    <TableCell>{lotItem.title}</TableCell>
                                    <TableCell>{lotItem.okpdCode} {lotItem.okpdName} {lotItem.ktruCode} {lotItem.ktruName}</TableCell>
                                    <TableCell>{lotItem.price ? lotItem.price + ' ₽' : '—'}</TableCell>
                                    <TableCell>{lotItem.quantity}</TableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                              : null
                            }

                          <TableRow>
                            <TableCell colSpan="4"></TableCell>
                          </TableRow>

                        </TableBody>
                      )}
                  </Table>
                </TableContainer>  
            </CardContent>
          </Card>  
        </React.Fragment>
      )
    }
}

export default withRouter(PurchaseLotDetails);