import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import OrganizationResult from './OrganizationResult';

// MUI
import { Button, Card, CardContent, Grid, TextField, CircularProgress, Box, tablePaginationClasses } from '@mui/material';
import CustomerService from '../../services/CustomerService';

class Organizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: null,
      organizationInn: this.props.organizationInn,
      activeTab: this.props.activeTab
    };
  }

  render() {
    return (
      <React.Fragment>
        <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      ИНН:
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="inn"
                        variant="outlined"
                        fullWidth
                        onChange={(event) => {
                          var organizationInn = event.target.value;
                          this.setState({
                            organizationInn: organizationInn
                          });
                        }}
                        value={this.state.organizationInn}
                      />
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => this.props.onSearchOrganization(this.state)}
                  variant="contained"
                >Найти</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {this.props.isLoading 
          ? 
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
          :
          <OrganizationResult/>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    organizationInn: state.organizationsState.organizationInn,
    organization: state.organizationsState.organization,
    isLoading: state.organizationsState.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSearchOrganization: (state) => {
      const action = {
        type: 'SEARCH_ORGANIZATION'
      };
      dispatch(action);

      CustomerService.getOrganizationByInn(state.organizationInn)
        .then(result => {
          const action = {
            type: 'ORGANIZATION_SEARCH_COMPLETED',
            organization: result
          }

          dispatch(action);
        });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Organizations));