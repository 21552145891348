import React, { useState } from 'react';
import { Card, Button, Dialog, Typography, Grid, TextField, Checkbox, Box, Tab, CircularProgress } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSelector, useDispatch } from "react-redux";
import CustomerService from '../services/CustomerService';
import InfiniteScroll from 'react-infinite-scroll-component';

const size = 50;
const infiniteScrollElementDomName = "scrollableDom";

export const SearchResultsTabId = "0";
export const CheckedTabId = "1";

export function OrganizationCard(props) {
  return (
    <React.Fragment>
      <Card>
        <Typography>
          {props.organization.name}
        </Typography>

        {props.organization.sourcePostalAddress 
        ?
        <Grid>
          <Typography variant='caption'>
            {props.organization.sourceActualAddress}
          </Typography>
        </Grid>
        :
        null
        }

        <Grid>
          <Typography variant='caption'>
            {props.organization.inn
            ?
            `ИНН: ${props.organization.inn} `
            : 
            null
            }
          </Typography>
          <Typography variant='caption'>
            {props.organization.kpp
            ?
            `КПП: ${props.organization.kpp} `
            : 
            null
            }
          </Typography>
          <Typography variant='caption'>
            {props.organization.ogrn
            ?
            `ОГРН: ${props.organization.ogrn} `
            : 
            null
            }
          </Typography>
        </Grid>
      </Card>
    </React.Fragment>
  )
}

export function CustomerSelection(props) {
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();
  const reduxState = useSelector((state) => {
      return {
        openModal: state.customersState.openModal,
        foundOrganizations: state.customersState.foundOrganizations,
        checkedOrganizations: state.customersState.checkedOrganizations,
        activeTabId: state.customersState.activeTabId,
        isLoading: state.customersState.isLoading,
        foundOrganizationsTotalCount: state.customersState.foundOrganizationsTotalCount,
        currentPage: state.pagesState.currentPage
      };
  })

  const onClose = () => {
    const action = {
      type: 'CUSTOMER_SELECTION_CLOSED'
    };
    dispatch(action);
  }

  const onSearch = (event) => {
    event.preventDefault();
    if (searchText.length > 2) {
      const action = {
        type: 'CUSTOMER_SELECTION_SEARCH_STARTED'
      };
      dispatch(action);

      CustomerService.searchOrganization({
        keywords: searchText,
        size: size, 
        from: 0
      })
      .then((result) => {
        const action = {
          type: 'CUSTOMER_SELECTION_FOUND',
          foundOrganizations: result.collections,
          foundOrganizationsTotalCount: result.total
        };
        dispatch(action);
      })
      .catch((error) => {
        const action = {
          type: 'CUSTOMER_SELECTION_SEARCH_FAILED'
        };
        dispatch(action);
      })
    }
  }

  const onScroll = () => {
    CustomerService.searchOrganization({
      keywords: searchText,
      size: size, 
      from: reduxState.foundOrganizations.length
    })
    .then((result) => {
      const action = {
        type: 'CUSTOMER_SELECTION_ADDED',
        addedOrganizations: result.collections
      };
      dispatch(action);
    })
  }

  const onChangeOrganizationCheckbox = (selectedOrganization, checked) => {
    const action = {
      type: 'CUSTOMER_SELECTION_CHECKBOX_CHANGED_SINGLE',
      selectedOrganization: selectedOrganization,
      checked: checked
    };
    dispatch(action);
  }

  const onChangeAllOrganizationsCheckbox = (checked) => {
    const action = {
      type: 'CUSTOMER_SELECTION_CHECKBOX_CHANGED_ALL',
      checked: checked
    };
    dispatch(action);
  }

  const onChangeTab = (tabId) => {
    const action = {
      type: 'CUSTOMER_SELECTION_TAB_CHANGED',
      tabId: tabId
    };
    dispatch(action);
  }

  const onConfirmSearch = () => {
    const action = {
      type: 'CUSTOMER_SELECTION_COMPLETED',
      customers: reduxState.checkedOrganizations,
      currentPage: reduxState.currentPage
    };
    dispatch(action);
  }

  return (
    <React.Fragment>
      <Dialog 
        open={reduxState.openModal} 
        onClose={onClose}
        maxWidth="60%"
        PaperProps={{sx: {
          width: "70%"
        }}}
      >
        <Grid container >
          <Card>
            <form onSubmit={onSearch}>
              <Grid sx={{ display: 'flex'}}>
                <Grid>
                  <TextField
                    autoFocus
                    size="small"
                    onChange={(event) => {
                      setSearchText(event.target.value);
                    }}
                  />
                </Grid>
                <Grid>
                  <Button type="submit">
                    Найти
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
        <TabContext value={reduxState.activeTabId}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Grid container>
              <Grid item xs={11}>
                <TabList onChange={(event, newValue) => {
                  onChangeTab(newValue);
                }}>
                  <Tab label={`Найдено ${reduxState.foundOrganizationsTotalCount > 0 ? `(${reduxState.foundOrganizationsTotalCount})` : ''}`} value={SearchResultsTabId} />
                  <Tab label={`Выбрано ${reduxState.checkedOrganizations.length > 0 ? `(${reduxState.checkedOrganizations.length})` : ''}`} value={CheckedTabId} />
                </TabList>
              </Grid>
              {
                reduxState.activeTabId === CheckedTabId
                ?
                  <Grid item xs={1}>
                    <Checkbox 
                      onClick={(event) => {
                        const checked = event.target.checked;
                        onChangeAllOrganizationsCheckbox(checked);
                      }}
                      checked={
                        (reduxState.activeTabId === SearchResultsTabId && reduxState.foundOrganizations.length > 0 && reduxState.foundOrganizations.every((item) => reduxState.checkedOrganizations.some((org) => org.id === item.id)))
                        ||
                        (reduxState.activeTabId === CheckedTabId && reduxState.checkedOrganizations.length > 0)
                      }
                    
                    />
                  </Grid>
                :
                  null
                }
            </Grid>
          </Box>
          <TabPanel value={SearchResultsTabId} sx={{ overflowY: "scroll" }} id={infiniteScrollElementDomName}>
            {
              reduxState.isLoading 
              ?
              <CircularProgress />
              :
                <div>
                  <InfiniteScroll
                    dataLength={reduxState.foundOrganizations.length}
                    next={onScroll}
                    hasMore={reduxState.foundOrganizations.length < reduxState.foundOrganizationsTotalCount}
                    loader={<h6>Загрузка...</h6>}
                    scrollThreshold={0.9}
                    scrollableTarget={infiniteScrollElementDomName}
                  >
                    {reduxState.foundOrganizations.map((organization, key) => (
                      <Grid container key={key} sx={{ display: 'flex', mt: 1, ml: 1}}>
                        <Grid item xs={11}>
                          <OrganizationCard organization={organization} />
                        </Grid>
                        <Grid item xs={1}>
                          <Checkbox
                            onChange={(event) => {
                              const checked = event.target.checked;
                              onChangeOrganizationCheckbox(organization, checked);
                            }} 
                            checked={reduxState.checkedOrganizations.some((org) => org.id === organization.id)}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </InfiniteScroll>
                </div>
              }
              
          </TabPanel>
          <TabPanel value={CheckedTabId} sx={{ overflowY: "scroll" }}>
            <Grid container>
              {reduxState.checkedOrganizations.map((organization, key) => (
                <Grid container key={key} sx={{ display: 'flex', mt: 1, ml: 1}}>
                  <Grid item xs={11}>
                    <OrganizationCard organization={organization} />
                  </Grid>
                  <Grid item xs={1}>
                      <Checkbox
                        onChange={(event) => {
                          const checked = event.target.checked;
                          onChangeOrganizationCheckbox(organization, checked);
                        }} 
                        checked={reduxState.checkedOrganizations.some((org) => org.id === organization.id)}
                      />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        </TabContext>
        <Grid textAlign="end">
          <Button onClick={onConfirmSearch}>Принять</Button>
          <Button onClick={onClose}>Отмена</Button>
        </Grid>
      </Dialog>
    </React.Fragment>
  )
}
