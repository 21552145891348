import React from 'react';
import { connect } from 'react-redux';
import { Card, Button, Stack, Grid, TextField, Checkbox, FormControlLabel, CardContent, Autocomplete, FormGroup, Chip, Link, InputAdornment, Tooltip, IconButton, CircularProgress } from '@mui/material';
import TemplateService from '../services/TemplateService.js';
import PurchaseSearchResult from '../components/PurchaseSearchResult';
import PurchaseService from '../services/PurchaseService';
import SettlementService from '../services/SettlementService';
import PurchaseTagsService from '../services/PurchaseTagsService';
import CustomerRatingService from '../services/CustomerRatingService.js';
import { Box } from '@mui/system';
import NumberFormat from 'react-number-format';
import OkpdSelection from './OkpdSelection';
import IndustrySelection from './IndustrySelection.js';
import { CustomerSelection } from './CustomerSelection.js';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CustomDatepicker } from './CustomDatepicker';
import { CustomDatepickerFromTo } from './CustomDatepickerFromTo.js';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=' '
      isNumericString
      decimalScale={0}
    />
  );
});

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settlementsInputTimeout: null
    };

    this.handleStageChange = this.handleStageChange.bind(this);
    this.handleAuctionTypeChange = this.handleAuctionTypeChange.bind(this);
    this.handleDayOfWeekNotificationChange = this.handleDayOfWeekNotificationChange.bind(this);
    this.handlePurchaseTypeChange = this.handlePurchaseTypeChange.bind(this);
    this.handlePageSearch = this.handlePageSearch.bind(this);
    this.handleShouldIncludeEmptyPriceChange = this.handleShouldIncludeEmptyPriceChange.bind(this);

    this.searchResultsRef = React.createRef();
  }

  componentDidMount() {
    this.props.onInitTemplatePage();
    this.props.onGetTemplates();
  }

  //rerun search when sortBy changed
  componentDidUpdate(prevProps) {
    if (prevProps.sortBy !== this.props.sortBy || prevProps.ascending !== this.props.ascending){
      this.props.onSearchPurchases(this.props, 0, false);
    }
  }

  handlePageSearch(pageNumber){
    this.searchResultsRef.current.scrollIntoView(); //scroll to top of results
    this.props.onSearchPurchases(this.props, pageNumber, false); //(props, currentPageNumber, resetMarkedPurchases)
  }

  handleStageChange(event) {
    var isChecked = event.target.checked;
    var value = parseInt(event.target.value);

    if (isChecked) {
      this.props.onAddStage(value);
    } else {
      this.props.onRemoveStage(value);
    }
  }

  handleShouldIncludeEmptyPriceChange(event) {
    var isChecked = event.target.checked;
    this.props.onShouldIncludeEmptyPriceChanges(isChecked);
  }

  handleAuctionTypeChange(event) {
    var isChecked = event.target.checked;
    var value = parseInt(event.target.value);

    if (isChecked) {
      this.props.onAddAuctionType(value);
    } else {
      this.props.onRemoveAuctionType(value);
    }
  }

  handlePurchaseTypeChange(event) {
    var isChecked = event.target.checked;
    var value = parseInt(event.target.value);

    if (isChecked) {
      this.props.onAddLawChanges(value);
    } else {
      this.props.onRemoveLawChanges(value);
    }
  }

  handleDayOfWeekNotificationChange(event) {
    var isChecked = event.target.checked;
    var value = parseInt(event.target.value);

    if (isChecked) {
      this.props.onAddDayNotify(value);
    } else {
      this.props.onRemoveDayNotify(value);
    }
  }  

  closeDialog = () => {
    this.props.onCloseTagDialog(this.props.selectedPurchase.id);
  }

  render() {
    var result;
    if (this.props.isTemplatesLoading === true) {
      return <CircularProgress />
    }
    else {
      if (this.props.templates.length > 0) {
        const items = this.props.templates.map(template => {
          var variant = template.id == this.props.templateId ? "contained" : "outlined";
          return <Box sx={{ p: 0.5 }} key={template.id}>
            <Button variant={variant} onClick={() => this.props.onGetTemplate(template.id, this.props)}>{template.name}</Button>
          </Box>
        });
        // result = <Stack direction="row" spacing={2}>{items}</Stack>;
        result = 
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            // flexDirection: 'row',
            p: 1,
            m: 1,
            // bgcolor: 'background.paper',
            // borderRadius: 1,
          }}      
        >{items}</Box>
      } else {
        result = "Шаблонов еще нет"
      }
    }

    return (
      <React.Fragment>
        <Stack spacing={2}>
          {result}
          {/* <Button variant="outlined">Outlined</Button> */}
        </Stack>

        <OkpdSelection />
        <IndustrySelection />
        <CustomerSelection />

        {this.props.templateId ?
          <React.Fragment>
            <form onSubmit={(event) => { event.preventDefault(); }} >
            <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Наименование шаблона
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          id="template_name"
                          variant="outlined"
                          fullWidth
                          onChange={(event) => this.props.onTemplateNameChanges(event.target.value)}
                          value={this.props.name}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Уведомлять на почту о новых закупках
                      </Grid>
                      <Grid item xs={10}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label=""
                            sx={{ width: 180 }}
                            onChange={(event) => {
                              if (event.target.checked) {
                                this.props.onEmailNotifyEnable();
                              } else {
                                this.props.onEmailNotifyDisable();
                              }
                            }}
                            checked={this.props.notifyIsEnabled}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Уведомление
                      </Grid>
                      <Grid item xs={10}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Понедельник"
                            sx={{ width: 180 }}
                            onChange={this.handleDayOfWeekNotificationChange}
                            value="1"
                            checked={this.props.notifyDays.some(notifyDay => notifyDay === 1)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Вторник"
                            sx={{ width: 180 }}
                            onChange={this.handleDayOfWeekNotificationChange}
                            value="2"
                            checked={this.props.notifyDays.some(notifyDay => notifyDay === 2)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Среда"
                            sx={{ width: 180 }}
                            onChange={this.handleDayOfWeekNotificationChange}
                            value="3"
                            checked={this.props.notifyDays.some(notifyDay => notifyDay === 3)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Четверг"
                            sx={{ width: 180 }}
                            onChange={this.handleDayOfWeekNotificationChange}
                            value="4"
                            checked={this.props.notifyDays.some(notifyDay => notifyDay === 4)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Пятница"
                            sx={{ width: 180 }}
                            onChange={this.handleDayOfWeekNotificationChange}
                            value="5"
                            checked={this.props.notifyDays.some(notifyDay => notifyDay === 5)}
                          />   
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Суббота"
                            sx={{ width: 180 }}
                            onChange={this.handleDayOfWeekNotificationChange}
                            value="6"
                            checked={this.props.notifyDays.some(notifyDay => notifyDay === 6)}
                          />   
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Воскресенье"
                            sx={{ width: 180 }}
                            onChange={this.handleDayOfWeekNotificationChange}
                            value="7"
                            checked={this.props.notifyDays.some(notifyDay => notifyDay === 7)}
                          />                                                                        
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>                 

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Ключевые слова
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          id="keywords"
                          variant="outlined"
                          placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                          fullWidth
                          onChange={(event) => this.props.onKeywordsChanges(event.target.value)}
                          value={this.props.keywords.join(', ')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <Tooltip 
                                  title={
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                      { 
                                        " - если введены несколько слов через пробел \r\n"
                                        +" ищутся закупки содержащие их все; \r\n"
                                        +" - альтернативные варианты вводятся через запятую; \r\n"
                                        +" - для поиска по маске используются знаки * и ?; \r\n"
                                        +" - * заменяет любое количество любых символов; \r\n"
                                        +" пример: \r\n"
                                        +" запрос \"газ*\" вернет \"газообразный\", \"газель\" \r\n"
                                        +" запрос \"бе*ый\" вернет \"белый\", \"бешеный\" \r\n"
                                        +" не рекомендуется использовать * в начале слова \r\n"
                                        +" - ? заменяет один любой символ \r\n"
                                        +" пример: \r\n"
                                        +" запрос \"?аска\" вернет \"маска\", \"ласка\" \r\n"
                                        +" запрос \"ию?ь\" вернет \"июль\", \"июнь\" \r\n"
                                      }
                                    </div>
                                  }>
                                  <IconButton size="small">
                                    <HelpOutlineIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Ключевые слова для поиска в найденном
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          multiline={true}
                          maxRows={6}
                          id="searchInFoundKeywords"
                          variant="outlined"
                          fullWidth
                          onChange={(event) => { this.props.onsearchInFoundKeywordsChanges(event.target.value); }}
                          value={this.props.searchInFoundKeywords.join(',')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Исключать слова
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          id="keywords"
                          variant="outlined"
                          fullWidth
                          onChange={(event) => { this.props.onIgnoreKeywordsChanges(event.target.value); }}
                          value={this.props.ignoreKeywords.join(', ')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      </Grid>
                      <Grid item xs={5}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Искать в приложенных документах"
                            onChange={(event) => this.props.onShouldSearchInDocumentsChanges(event.target.checked)}
                            checked={this.props.shouldSearchInDocuments}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={5}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Искать в наименованиях ОКПД2"
                            onChange={(event) => this.props.onShouldSearchInOkpdNamesChanges(event.target.checked)}
                            checked={this.props.shouldSearchInOkpdNames}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        ОКПД
                      </Grid>
                      <Grid item xs={8}>
                        <Stack direction="row" spacing={1}>
                          { this.props.okpdCodes.map((okpd, key) =>
                              <Chip label={okpd.key} key={key} onDelete={() => this.props.onRemoveSingleOkpdSelection(okpd.key)}/>
                          )} 
                        </Stack>
                        <Stack direction="column" spacing={1} sx={{mt: 1}}>
                          { this.props.industryCodes.map((industry, key) =>
                              <Chip label={industry.title} key={key} onDelete={() => this.props.onRemoveSingleIndustrySelection(industry.key)}/>
                          )} 
                        </Stack>
                      </Grid>
                      <Grid item xs={1}>
                        <Button onClick={() => this.props.onOpenIndustrySelection(this.props.industryCodes)}>Выбор отраслей</Button>
                      </Grid>
                      <Grid item xs={1}>
                        <Button onClick={() => this.props.onOpenOkpdSelection(this.props.okpdCodes)}>Выбор ОКПД</Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Регион поставки
                      </Grid>
                      <Grid item xs={10}>
                      <Autocomplete
                      freeSolo
                      multiple
                      clearOnBlur
                      forcePopupIcon={false}
                      autoHighlight
                      id="checkboxes-tags-demo"
                      options={this.props.settlements}
                      filterOptions={(options) => options}
                      renderTags={(values, getTagProps) => 
                        values.map((value, key) => 
                          <Tooltip 
                            title={value.fullRegionName}
                            key={key}
                          >
                            <Chip
                              {...getTagProps({ key })}
                              label={`${value.shortObjectType} ${value.name}`}
                            />
                          </Tooltip>
                        )
                      }
                      getOptionLabel={(option) => option.shortObjectType + ' '+option.name }
                      loading = {this.props.settlementsLoading}
                      renderOption={(props, option, { selected }) => {
                        if (!this.props.kladrCodes.some((kladrCode) => kladrCode === option.code)) {
                          if (option.fullRegionName) {
                            return (<li {...props} key={option.code}>
                              {option.shortObjectType + ' ' + option.name + ' '}
                              <p style={{ color: "#aeb3b7" }}>
                                {'\u00a0' + option.fullRegionName}
                              </p>
                            </li>)
                          } else {
                            return (<li {...props} key={option.code}>
                              <p>
                                {option.shortObjectType + ' ' + option.name + ' '}
                              </p>
                            </li>)
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Область, округ, республика, край, город, деревня, село"
                          onChange={(event) => {
                            const value = event.target.value;
                            if (value) {
                              if (this.state.settlementsInputTimeout !== null){
                                clearTimeout(this.state.settlementsInputTimeout);
                              }
                              this.setState({settlementsInputTimeout: setTimeout(() => {
                                this.props.onSearchSettlements(value);
                              }, 500)});                              
                            }
                            else {
                              clearTimeout(this.state.settlementsInputTimeout);
                              this.props.onClearSettlements();
                            }
                          }}
                          onKeyDown={(event) => {
                              if (event.key === 'Enter' && this.props.settlements.length === 0) {
                                event.stopPropagation();
                              }
                            }
                          }
                        />
                      )}
                      onChange={(event, options) => {
                        this.props.onKladrCodesChanges(options);
                      }}
                      onClose={(event, reason) => {
                        this.props.onClearSettlements();
                      }}
                      value={this.props.kladrCodes}
                    />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Этап
                      </Grid>
                      <Grid item xs={10}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Подача заявок"
                            sx={{ width: 180 }}
                            onChange={this.handleStageChange}
                            value={1}
                            checked={this.props.stages.some(stage => stage === 1)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Работа комиссии"
                            sx={{ width: 180 }}
                            onChange={this.handleStageChange}
                            value="2"
                            checked={this.props.stages.some(stage => stage == '2')}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Завершены"
                            sx={{ width: 180 }}
                            onChange={this.handleStageChange}
                            value={3}
                            checked={this.props.stages.some(stage => stage === 3)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Отменены"
                            sx={{ width: 180 }}
                            onChange={this.handleStageChange}
                            value={4}
                            checked={this.props.stages.some(stage => stage == 4)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Не состоялись"
                            sx={{ width: 180 }}
                            onChange={this.handleStageChange}
                            value={5}
                            checked={this.props.stages.some(stage => stage == 5)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Планируются"
                            sx={{ width: 180 }}
                            onChange={this.handleStageChange}
                            value={6}
                            checked={this.props.stages.some(stage => stage == 6)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="На исполнении"
                            sx={{ width: 180 }}
                            onChange={this.handleStageChange}
                            value={7}
                            checked={this.props.stages.some(stage => stage == 7)}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Метод определения поставщика
                      </Grid>
                      <Grid item xs={10}>
                        <FormGroup sx={{  display: 'block', flexDirection: 'column' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            sx={{ display: 'block'}}
                            label="Аукцион"
                            onChange={this.handleAuctionTypeChange }
                            value={1}
                            checked={this.props.auctionTypes.some(auctionType => auctionType === 1)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            sx={{ display: 'block'}}
                            label="Запрос предложений"
                            onChange={this.handleAuctionTypeChange }
                            value={2}
                            checked={this.props.auctionTypes.some(auctionType => auctionType === 2)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            sx={{ display: 'block'}}
                            label="Запрос котировок"
                            onChange={this.handleAuctionTypeChange }
                            value={3}
                            checked={this.props.auctionTypes.some(auctionType => auctionType === 3)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            sx={{ display: 'block'}}
                            label="Конкурс"
                            onChange={this.handleAuctionTypeChange }
                            value={4}
                            checked={this.props.auctionTypes.some(auctionType => auctionType === 4)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            sx={{ display: 'block'}}
                            label="Закупка у единственного поставщика"
                            onChange={this.handleAuctionTypeChange }
                            value={5}
                            checked={this.props.auctionTypes.some(auctionType => auctionType === 5)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            sx={{ display: 'block'}}
                            label="Закупка иным способом"
                            onChange={this.handleAuctionTypeChange }
                            value={6}
                            checked={this.props.auctionTypes.some(auctionType => auctionType === 6)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            sx={{ display: 'block'}}
                            label="Предварительный квалификационный отбор"
                            onChange={this.handleAuctionTypeChange }
                            value={7}
                            checked={this.props.auctionTypes.some(auctionType => auctionType === 7)}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Тип торгов
                      </Grid>
                      <Grid item xs={10}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="44-ФЗ"
                            sx={{ width: 180 }}
                            onChange={this.handlePurchaseTypeChange}
                            value={1}
                            checked={this.props.laws.some(law => law === 1)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="223-ФЗ"
                            sx={{ width: 180 }}
                            onChange={this.handlePurchaseTypeChange}
                            value={2}
                            checked={this.props.laws.some(law => law === 2)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Коммерческие"
                            sx={{ width: 180 }}
                            onChange={this.handlePurchaseTypeChange}
                            value={3}
                            checked={this.props.laws.some(law => law === 3)}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="ПП РФ 615"
                            sx={{ width: 180 }}
                            onChange={this.handlePurchaseTypeChange}
                            value={4}
                            checked={this.props.laws.some(law => law === 4)}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Наличие аванса
                      </Grid>
                      <Grid item xs={10}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label=""
                            sx={{ width: 180 }}
                            onChange={(event) => this.props.onHavePrepaymentChanges(event.target.checked)}
                            checked={this.props.havePrepayment}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Рекомендация "Эксперт Центр"
                      </Grid>
                      <Grid item xs={10}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label=""
                            sx={{ width: 180 }}
                            onChange={(event) => this.props.onHaveRecommendationChanges(event.target.checked)}
                            checked={this.props.haveRecommendation}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>                  

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Начальная цена
                      </Grid>
                      <Grid item xs={5} sx={{ display: 'flex' }}>
                        <TextField
                          value={this.props.priceFrom ? this.props.priceFrom : ''}
                          onChange={(event) => {
                            const priceFrom = event.target.value;
                            this.props.onPriceFromChanges(priceFrom);
                          }}
                          name="numberformat"
                          placeholder="0"
                          id="formatted-numberformat-input"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          variant="outlined"
                        />
                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                          –
                        </Box>
                        <TextField
                          value={this.props.priceTo ? this.props.priceTo : ''}
                          onChange={(event) => {
                            const priceTo = event.target.value;
                            this.props.onPriceToChanges(priceTo);
                          }}
                          name="numberformat"
                          placeholder="0"
                          id="formatted-numberformat-input"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          variant="outlined"
                        />
                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                          ₽
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Включая закупки без НМЦ"
                            sx={{ width: 250 }}
                            onChange={this.handleShouldIncludeEmptyPriceChange}
                            // value={1}
                            checked={this.props.shouldIncludeEmptyPrice}
                          />
                        </FormGroup>
                      </Grid>      
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Опубликовано
                      </Grid>
                      <Grid item xs={10} sx={{ display: 'flex' }}>
                        <CustomDatepickerFromTo
                          dateFrom={this.props.datePublicationFrom}
                          onDateFromChanges={this.props.onDatePublicationFromChanges}
                          dateTo={this.props.datePublicationTo}
                          onDateToChanges={this.props.onDatePublicationToChanges}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}> 
                        Дата окончания подачи заявок не раньше чем
                      </Grid>
                      <Grid item xs={10} sx={{ display: 'flex' }}>
                        <CustomDatepicker
                          date={this.props.dateExpirationFrom}
                          onDateChanges={this.props.onDateExpirationFromChanges}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            Заказчик
                        </Grid>
                        <Grid item container xs={10} sx={{ display: 'flex' }}>
                          {
                            this.props.customers.length > 0
                            ?
                            <Grid item xs={12}>
                              <Chip label={ `Выбрано компаний: ${this.props.customers.length}` } />
                            </Grid>
                            :
                            null
                          }
                          <Grid item xs={12}>
                            <Link onClick={() => this.props.onOpenSearchOrganizations(this.props.customers)}>
                              {
                              this.props.customers.length > 0
                              ?
                              'изменить'
                              :
                              'добавить'
                              }
                            </Link>
                          </Grid>
                        </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} >
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ mr: 0.5 }}>
                        <Button
                          onClick={() => this.props.onSearchPurchases(this.props, 0, true)} //(props, currentPageNumber, resetMarkedPurchases)
                          variant="contained"
                          type="submit"
                        >
                          Найти
                        </Button>
                      </Box>
                      <Box sx={{ mr: 0.5 }}>
                        <Button
                          onClick={() => this.props.onSaveTemplate(this.props)}
                          variant="contained"
                          disabled={!this.props.canSaveTemplate}
                          color="success"
                        >
                          Сохранить изменения
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          onClick={() => this.props.onRemoveTemplate(this.props)}
                          variant="outlined"
                          color="error"
                        >
                          Удалить шаблон
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: -3 }}>
                    <Button size="small" onClick={() => this.props.onGetTemplate(this.props.templateId, this.props)}>Сбросить шаблон</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box ref={this.searchResultsRef}>
              <PurchaseSearchResult onPageSearch={this.handlePageSearch}  />
            </Box>
            </form>
          </React.Fragment>
          : null
        }

      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    keywords: state.templatesState.keywords,
    searchInFoundKeywords: state.templatesState.searchInFoundKeywords,
    ignoreKeywords: state.templatesState.ignoreKeywords,
    shouldSearchInDocuments: state.templatesState.shouldSearchInDocuments,
    shouldSearchInOkpdNames: state.templatesState.shouldSearchInOkpdNames,
    havePrepayment: state.templatesState.havePrepayment,
    haveRecommendation: state.templatesState.haveRecommendation,
    laws: state.templatesState.laws,
    datePublicationFrom: state.templatesState.datePublicationFrom,
    datePublicationTo: state.templatesState.datePublicationTo,
    dateExpirationFrom: state.templatesState.dateExpirationFrom,
    settlements: state.templatesState.settlements,
    priceFrom: state.templatesState.priceFrom,
    priceTo: state.templatesState.priceTo,
    stages: state.templatesState.stages,
    kladrCodes: state.templatesState.kladrCodes,
    auctionTypes: state.templatesState.auctionTypes,
    okpdCodes: state.templatesState.okpdCodes,
    industryCodes: state.templatesState.industryCodes,
    customers: state.templatesState.customers,
    shouldIncludeEmptyPrice: state.templatesState.shouldIncludeEmptyPrice,

    sortBy: state.templatesState.sortBy,
    ascending: state.templatesState.ascending,

    templateId: state.templatesState.templateId,
    templates: state.templatesState.templates,
    canSaveTemplate: state.templatesState.canSaveTemplate,
    purchases: state.templatesState.purchases,
    name: state.templatesState.name,
    isSuccessNotificationShow: state.templatesState.isSuccessNotificationShow,
    notificationMessage: state.templatesState.notificationMessage,
    notificationKey: state.templatesState.notificationKey,
    notifyDays: state.templatesState.notifyDays,
    notifyIsEnabled: state.templatesState.notifyIsEnabled,

    settlementsLoading: state.templatesState.settlementsLoading,

    selectedPurchase: state.templatesState.selectedPurchase,
    openTagDialog: state.templatesState.openTagDialog,

    isTemplatesLoading: state.templatesState.isTemplatesLoading,

    pageNumber: state.templatesState.pageNumber,
    currentPage: state.pagesState.currentPage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onInitTemplatePage: () => {
      const action = {
        type: 'TEMPLATE_PAGE'
      };
      dispatch(action);
    },
    onSearchPurchases: (state, pageNumber, resetMarkedPurchases) => {
      const action = {
        type: 'TEMPLATES_SEARCH_PURCHASES'
      };
      dispatch(action);

      PurchaseService.search({
        keywords: state.keywords,
        searchInFoundKeywords: state.searchInFoundKeywords,
        ignoreKeywords: state.ignoreKeywords,
        shouldSearchInDocuments: state.shouldSearchInDocuments,
        shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
        havePrepayment: state.havePrepayment,
        haveRecommendation: state.haveRecommendation,
        laws: state.laws,
        datePublicationFrom: state.datePublicationFrom,
        datePublicationTo: state.datePublicationTo,
        dateExpirationFrom: state.dateExpirationFrom,
        priceFrom: parseInt(state.priceFrom),
        priceTo: parseInt(state.priceTo),
        stages: state.stages,
        kladrCodes: state.kladrCodes.map(opt => opt.code),
        auctionTypes: state.auctionTypes,
        okpdCodes: state.okpdCodes.flatMap(opt => {
          if (opt.children.length > 0) {
            return opt.children;
          }
          return opt.key;
        }),
        industryCodes: state.industryCodes.map(opt => {
          return opt.key;
        }),
        customerIds: state.customers.map(opt => {
          return opt.id;
        }),
        shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
        pageNumber: pageNumber,
        sortBy: state.sortBy,
        asc: state.ascending
      })
        .then(result => {
          var customerOrgIds = result.collections.map(c => c.customerOrganizationId);

          CustomerRatingService.getCustomerRatings(customerOrgIds)
          .then(result => {
            if (result)
            {
              const action = {
                type: 'TEMPLATES_CUSTOMER_RATINGS_FOUND',
                customerRatings: result
              };
              dispatch(action);
            }
          })
          .catch((error) => {
            const notificationAction = {
              type: 'SNACKBAR_NOTIFICATION_SENT',
              notification: {
                message: 'Произошла ошибка при получении оценок заказчиков',
                variant: 'error'
              }
            };
            dispatch(notificationAction);
          });

          var purchaseIds = result.collections.map(c => c.id);
          PurchaseTagsService.getPurchaseTags(purchaseIds)
          .then(result => {
            const action = {
              type: 'TEMPLATES_PURCHASE_TAGS_FOUND',
              purchaseTags: result
            };
            dispatch(action);
          })
          .catch((error) => {
            const notificationAction = {
              type: 'SNACKBAR_NOTIFICATION_SENT',
              notification: {
                message: 'Произошла ошибка при получении меток избранного',
                variant: 'error'
              }
            };
            dispatch(notificationAction);
          });
          
          const action = {
            type: 'PURCHASES_FOUND',
            purchases: result.collections,
            pageNumber: result.pageNumber,
            pageSize: result.pageSize,
            total: result.total,
            pageCount: result.pageCount,
            keywords: state.keywords,
            searchInFoundKeywords: state.searchInFoundKeywords,
            ignoreKeywords: state.ignoreKeywords,
            shouldSearchInDocuments: state.shouldSearchInDocuments,
            shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
            havePrepayment: state.havePrepayment,
            haveRecommendation: state.haveRecommendation,
            laws: state.laws,
            datePublicationFrom: state.datePublicationFrom,
            datePublicationTo: state.datePublicationTo,
            dateExpirationFrom: state.dateExpirationFrom,
            priceFrom: state.priceFrom,
            priceTo: state.priceTo,
            stages: state.stages,
            auctionTypes: state.auctionTypes,
            kladrCodes: state.kladrCodes,
            okpdCodes: state.okpdCodes,
            industryCodes: state.industryCodes,
            customers: state.customers,
            shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
            currentPage: state.currentPage,
            resetMarkedPurchases: resetMarkedPurchases
          };
          dispatch(action);
        })
        .catch((error) => {
          const action = {
            type: 'TEMPLATES_SEARCH_PURCHASES_FAILED'
          };
          dispatch(action);

          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при поиске закупок по шаблону',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        })
    },
    onSearchSettlements: (keyword) => {
      const action = {
        type: 'TEMPLATES_SETTLEMENTS_SEARCH'
      };
      dispatch(action);
      SettlementService.search(keyword)
        .then(settlements => {
          const action = {
            type: 'TEMPLATES_SETTLEMENTS_FOUND',
            settlements: settlements
          };
          dispatch(action);
        })
        .catch((error) => {
          const action = {
            type: 'TEMPLATES_SETTLEMENTS_SEARCH_FAILED'
          };
          dispatch(action);

          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при поиске адреса',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        })
    },
    onClearSettlements: (keyword) => {
      const action = {
        type: 'TEMPLATES_SETTLEMENTS_RESET'
      };
      dispatch(action);
    },
    onGetTemplate: (templateId, state) => {
      TemplateService.getById(templateId)
      .then(template => {
        
        const action = {
          type: 'GET_TEMPLATE',
          keywords: template.keywords,
          searchInFoundKeywords: template.searchInFoundKeywords,
          ignoreKeywords: template.ignoreKeywords,
          shouldSearchInDocuments: template.shouldSearchInDocuments,
          shouldSearchInOkpdNames: template.shouldSearchInOkpdNames,
          havePrepayment: template.havePrepayment,
          haveRecommendation: template.haveRecommendation,
          laws: template.laws,
          datePublicationFrom: template.datePublicationFrom,
          datePublicationTo: template.datePublicationTo,
          dateExpirationFrom: template.dateExpirationFrom,
          priceFrom: template.priceFrom,
          priceTo: template.priceTo,
          stages: template.stages,
          kladrCodes: template.kladrCodes,
          auctionTypes: template.auctionTypes,
          okpdCodes: template.okpdCodes,
          industryCodes: template.industryCodes,
          customers: template.customers,
          shouldIncludeEmptyPrice: template.shouldIncludeEmptyPrice,
          
          name: template.name,
          templateId: templateId,

          purchases: [],
          notifyIsEnabled: template.emailNotificationOptions ? template.emailNotificationOptions.isEnabled : false,
          notifyDays: template.emailNotificationOptions ? template.emailNotificationOptions.notifyDays : [],
          currentPage: state.currentPage
        };

        dispatch(action);

        PurchaseService.search({
          keywords: template.keywords,
          searchInFoundKeywords: template.searchInFoundKeywords,
          ignoreKeywords: template.ignoreKeywords,
          shouldSearchInDocuments: template.shouldSearchInDocuments,
          shouldSearchInOkpdNames: template.shouldSearchInOkpdNames,
          havePrepayment: template.havePrepayment,
          haveRecommendation: template.haveRecommendation,
          laws: template.laws,
          datePublicationFrom: template.datePublicationFrom,
          datePublicationTo: template.datePublicationTo,
          dateExpirationFrom: template.dateExpirationFrom,
          priceFrom: parseInt(template.priceFrom),
          priceTo: parseInt(template.priceTo),
          stages: template.stages,
          kladrCodes: template.kladrCodes.map(opt => opt.code),
          auctionTypes: template.auctionTypes,
          okpdCodes: template.okpdCodes.flatMap(item => {
            if (item.children.length > 0) {
              return item.children;
            }
            return item.key;
          }),
          industryCodes: template.industryCodes.map(opt => {
            return opt.key;
          }),
          customerIds: template.customers.map(opt => {
            return opt.id;
          }),
          shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
          sortBy: state.sortBy,
          asc: state.ascending
        })
          .then(result => {
            var customerOrgIds = result.collections.map(c => c.customerOrganizationId);

          CustomerRatingService.getCustomerRatings(customerOrgIds)
            .then(result => {
              if (result)
              {
                const action = {
                  type: 'TEMPLATES_CUSTOMER_RATINGS_FOUND',
                  customerRatings: result
                };
                dispatch(action);
              }
            })
            .catch((error) => {
              const notificationAction = {
                type: 'SNACKBAR_NOTIFICATION_SENT',
                notification: {
                  message: 'Произошла ошибка при получении оценок заказчиков',
                  variant: 'error'
                }
              };
              dispatch(notificationAction);
            });

            var purchaseIds = result.collections.map(c => c.id);
            PurchaseTagsService.getPurchaseTags(purchaseIds)
            .then(result => {
              const action = {
                type: 'TEMPLATES_PURCHASE_TAGS_FOUND',
                purchaseTags: result
              };
              dispatch(action);
            })
            .catch((error) => {
              const notificationAction = {
                type: 'SNACKBAR_NOTIFICATION_SENT',
                notification: {
                  message: 'Произошла ошибка при получении меток избранного',
                  variant: 'error'
                }
              };
              dispatch(notificationAction);
            });

            const action = {
              type: 'PURCHASES_FOUND',
              purchases: result.collections,
              pageNumber: result.pageNumber,
              pageSize: result.pageSize,
              total: result.total,
              pageCount: result.pageCount,
              keywords: template.keywords,
              searchInFoundKeywords: template.searchInFoundKeywords,
              ignoreKeywords: template.ignoreKeywords,
              shouldSearchInDocuments: template.shouldSearchInDocuments,
              shouldSearchInOkpdNames: template.shouldSearchInOkpdNames,
              havePrepayment: template.havePrepayment,
              haveRecommendation: template.haveRecommendation,
              laws: template.laws,
              datePublicationFrom: template.datePublicationFrom,
              datePublicationTo: template.datePublicationTo,
              dateExpirationFrom: template.dateExpirationFrom,
              priceFrom: template.priceFrom,
              priceTo: template.priceTo,
              stages: template.stages,
              kladrCodes: template.kladrCodes,
              auctionTypes: template.auctionTypes,
              okpdCodes: template.okpdCodes,
              industryCodes: template.industryCodes,
              customers: template.customers,
              shouldIncludeEmptyPrice: template.shouldIncludeEmptyPrice,
              currentPage: state.currentPage
            };
            dispatch(action);
          })
          .catch((error) => {
            const notificationAction = {
              type: 'SNACKBAR_NOTIFICATION_SENT',
              notification: {
                message: 'Произошла ошибка при получении закупок по шаблону',
                variant: 'error'
              }
            };
            dispatch(notificationAction);
          })
      })
      .catch((error) => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Произошла ошибка при получении шаблона',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
      })
    },
    onGetTemplates: () => {
      const action = {
        type: 'GET_TEMPLATES_STARTED'
      };
      dispatch(action);
      TemplateService.getAll()
        .then(templates => {
          const action = {
            type: 'GET_TEMPLATES_FINISHED',
            templates: templates
          };
  
          dispatch(action);
        })
        .catch((error) => {
          const action = {
            type: 'GET_TEMPLATES_ERROR'
          };
          dispatch(action);

          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Не удалось получить доступные шаблоны',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
    },
    onKeywordsChanges: (keywords) => {
      const action = {
        type: 'TEMPLATES_KEYWORDS_CHANGES',
        keywords: keywords
      }
      dispatch(action);
    },
    onsearchInFoundKeywordsChanges: (searchInFoundKeywords) => {
      const action = {
        type: 'TEMPLATES_SEARCH_IN_FOUND_KEYWORDS_CHANGES',
        searchInFoundKeywords: searchInFoundKeywords
      }
      dispatch(action);
    },
    onIgnoreKeywordsChanges: (ignoreKeywords) => {
      const action = {
        type: 'TEMPLATES_IGNORE_KEYWORDS_CHANGES',
        ignoreKeywords: ignoreKeywords
      }
      dispatch(action);
    },
    onShouldSearchInDocumentsChanges: (value) => {
      const action = {
        type: 'TEMPLATES_SHOULD_SEARCH_IN_DOCUMENTS_CHANGES',
        shouldSearchInDocuments: value
      }
      dispatch(action);
    },
    onShouldSearchInOkpdNamesChanges: (value) => {
      const action = {
        type: 'TEMPLATES_SHOULD_SEARCH_IN_OKPD_NAMES_CHANGES',
        shouldSearchInOkpdNames: value
      };
      dispatch(action);
    },
    onKladrCodesChanges: (kladrCodes) => {
      const action = {
        type: 'TEMPLATES_KLADR_CODES_CHANGES',
        kladrCodes: kladrCodes
      }  
      dispatch(action);    
    },
    onAddStage: (stageId) => {
      const action = {
        type: 'TEMPLATES_ADD_STAGE',
        stageId: stageId
      }   
      dispatch(action);    
    },
    onRemoveStage: (stageId) => {
      const action = {
        type: 'TEMPLATES_REMOVE_STAGE',
        stageId: stageId
      }
      dispatch(action);
    },
    onAddAuctionType: (auctionTypeId) => {
      const action = {
        type: 'TEMPLATES_ADD_AUCTION_TYPE',
        auctionTypeId: auctionTypeId
      }   
      dispatch(action);    
    },
    onRemoveAuctionType: (auctionTypeId) => {
      const action = {
        type: 'TEMPLATES_REMOVE_AUCTION_TYPE',
        auctionTypeId: auctionTypeId
      }
      dispatch(action);
    },
    onPriceFromChanges: (value) => {
      const action = {
        type: 'TEMPLATES_PRICE_FROM_CHANGES',
        priceFrom: value
      }
      dispatch(action);      
    },
    onPriceToChanges: (value) => {
      const action = {
        type: 'TEMPLATES_PRICE_TO_CHANGES',
        priceTo: value
      }
      dispatch(action);
    },
    onHavePrepaymentChanges: (value) => {
      const action = {
        type: 'TEMPLATES_HAVE_PREPAYMENT_CHANGES',
        havePrepayment: value
      }
      dispatch(action);
    },
    onHaveRecommendationChanges: (value) => {
      const action = {
        type: 'TEMPLATES_HAVE_RECOMMENDATION_CHANGES',
        haveRecommendation: value
      }
      dispatch(action);
    },    
    onDatePublicationFromChanges: (value) => {
      const action = {
        type: 'TEMPLATES_DATE_PUBLICATION_FROM_CHANGES',
        datePublicationFrom: value
      }
      dispatch(action);
    },
    onDatePublicationToChanges: (value) => {
      const action = {
        type: 'TEMPLATES_DATE_PUBLICATION_TO_CHANGES',
        datePublicationTo: value
      }
      dispatch(action);
    },
    onDateExpirationFromChanges: (value) => {
      const action = {
        type: 'TEMPLATES_DATE_EXPIRATION_FROM_CHANGES',
        dateExpirationFrom: value
      };
      dispatch(action);
    },
    onAddLawChanges: (value) => {
      const action = {
        type: 'TEMPLATES_ADD_LAW_CHANGES',
        lawId: value
      }
      dispatch(action);      
    },
    onRemoveLawChanges: (value) => {
      const action = {
        type: 'TEMPLATES_REMOVE_LAW_CHANGES',
        lawId: value
      }
      dispatch(action);      
    },
    onShouldIncludeEmptyPriceChanges: (value) => {
      const action = {
        type: 'TEMPLATES_SHOULD_INCLUDE_EMPTY_PRICE_CHANGES',
        shouldIncludeEmptyPrice: value
      }
      dispatch(action);
    },
    onRemoveTemplate: (state) => {
      TemplateService.remove(state.templateId)
      .then(result => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Шаблон успешно удален',
            variant: 'success'
          }
        };
        dispatch(notificationAction);

        const action = {
          type: 'REMOVE_TEMPLATE',
          templateId: state.templateId,
          currentPage: state.currentPage
        };
        dispatch(action);
      })
      .catch((error) => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Произошла ошибка при удалении шаблона',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
      });
    },
    onSaveTemplate: (state) => {
      TemplateService.update(state.templateId, {
        name: state.name,
        keywords: state.keywords,
        searchInFoundKeywords: state.searchInFoundKeywords,
        ignoreKeywords: state.ignoreKeywords,
        shouldSearchInDocuments: state.shouldSearchInDocuments,
        shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
        havePrepayment: state.havePrepayment,
        haveRecommendation: state.haveRecommendation,
        laws: state.laws,
        datePublicationFrom: state.datePublicationFrom,
        datePublicationTo: state.datePublicationTo,
        dateExpirationFrom: state.dateExpirationFrom,
        priceFrom: state.priceFrom,
        priceTo: state.priceTo,
        stages: state.stages,
        kladrCodes: state.kladrCodes,
        auctionTypes: state.auctionTypes,
        okpdCodes: state.okpdCodes.map((code) => {
            return {key: code.key, children: code.children.flatMap((child) => child.key ?? child)}
        }),
        industryCodes: state.industryCodes,
        customers: state.customers,
        shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
        
        emailNotificationOptions: {
          notifyDays: state.notifyDays,
          isEnabled: state.notifyIsEnabled
        }
      })
        .then((template) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Шаблон успешно сохранен',
              variant: 'success'
            }
          };
          dispatch(notificationAction);
          
          const action = {
            type: 'TEMPLATE_SAVED',
            template: template
          }
          dispatch(action);
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при редактировании шаблона',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
    },
    onTemplateNameChanges: (templateName) => {
      const action = {
        type: 'TEMPLATE_NAME_CHANGES',
        templateName: templateName
      }
      dispatch(action);
    },
    onAddDayNotify: (value) => {
      const action = {
        type: 'ADD_DAY_NOTIFY',
        notifyDay: parseInt(value)
      }
      dispatch(action);      
    },
    onRemoveDayNotify: (value) => {
      const action = {
        type: 'REMOVE_DAY_NOTIFY',
        notifyDay: parseInt(value)
      }
      dispatch(action);
    },
    onEmailNotifyEnable: () => {
      const action = {
        type: 'EMAIL_NOTIFY_ENABLE'
      }
      dispatch(action);
    },
    onEmailNotifyDisable: () => {
      const action = {
        type: 'EMAIL_NOTIFY_DISABLE'
      }
      dispatch(action);
    },
    onCloseTagDialog: (purchaseId) => {
      const purchaseIds = [purchaseId];
      PurchaseTagsService.getPurchaseTags(purchaseIds)
        .then(result => {
          const action = {
            type: 'REFRESH_PURCHASE_TAGS',
            purchase: result
          };
          dispatch(action);
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Произошла ошибка при получении избранных закупок',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        });
    },
    onOpenTagDialog: (selectedPurchase) => {
      const action = {
        type: 'OPEN_TAG_DIALOG',
        selectedPurchase: selectedPurchase
      };
      dispatch(action);
    },
    onOpenOkpdSelection: (checkedOkpdCodes) => {
      const action = {
        type: 'OKPD_SELECTION_OPENED',
        checkedOkpdCodes: checkedOkpdCodes
      };
      dispatch(action);
    },
    onRemoveSingleOkpdSelection: (key) => {
      const action = {
        type: 'TEMPLATES_SINGLE_SELECTED_OKPD_REMOVED',
        key: key
      };
      dispatch(action);
    },
    onOpenIndustrySelection: (checkedIndustryCodes) => {
      const action = {
        type: 'INDUSTRIES_SELECTION_OPENED',
        checkedIndustryCodes: checkedIndustryCodes
      };
      dispatch(action);
    },
    onRemoveSingleIndustrySelection: (key) => {
      const action = {
        type: 'TEMPLATES_SINGLE_SELECTED_INDUSTRY_REMOVED',
        key: key
      };
      dispatch(action);
    },
    onOpenSearchOrganizations: (checkedOrganizations) => {
      const action = {
        type: 'CUSTOMER_SELECTION_OPENED',
        checkedOrganizations: checkedOrganizations
      };
      dispatch(action);
    }
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Templates);